import { React, useEffect, useState ,useMemo} from "react";
import axiosClient from "../axios-client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";
import jsPDF from "jspdf";
import "jspdf-autotable";
import visioninn from "../Images/visioninn.png"

const NewEmployee = () => {
    const [employeelist, setEmployeelist] = useState([]);
    const [sideModel, setSideModel] = useState(false);
    const [employeeId, setEmployeeId] = useState();
    const [isloading, setIsloading] = useState(false)
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]); 
    const [employeeUpdateId, setEmployeeUpdateId] = useState();
    const [formData, setFormData] = useState({
        name: '',
        date_of_birth: '',
        gender: '',
        contact_number: '',
        email: '',
        address: '',
        department_id: '',
        position_id: '',
        salary: '',
        hire_date: '',
        identy_number: '',
        salary_type: '',
    });
    const [formUpdateData, setFormUpdateData] = useState({
        id: '',
        name: '',
        date_of_birth: '',
        gender: '',
        contact_number: '',
        email: '',
        address: '',
        department_id: '',
        position_id: '',
        salary: '',
        hire_date: '',
        identy_number: '',
        salary_type: '',
    });
    
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleUpdateChange = (e) => {
        const { name, value } = e.target;
        setFormUpdateData({
          ...formUpdateData,
          [name]: value
        });
    };

    const hanldeSubmit = () => {
        let errorMessage = '';
      
        // Check if any required field is empty
        for (const field in formData) {
          if (!formData[field].trim()) {
            errorMessage += `${field.replace('_', ' ').toUpperCase()} is required.\n`;
          }
        }
      
        // If any required field is missing, show error message
        if (errorMessage) {
          toast.error(errorMessage.trim(), {
            position: "top-right",
            autoClose: 2500,
            theme: "colored",
          });
          return;
        }
      
        // Construct FormData object from formData state
        let formDataObj = new FormData();
        for (const key in formData) {
          formDataObj.append(key, formData[key]);
        }
        // alert(formDataObj);
        // Send POST request to the backend API
        axiosClient.post("employee", formDataObj)
          .then((response) => {
            // Show success toast notification
            toast.success(`${formData.name} added successfully`, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
      
            // Clear fields after successful submission
            setFormData({
              name: '',
              date_of_birth: '',
              gender: '',
              contact_number: '',
              email: '',
              address: '',
              department_id: '',
              position_id: '',
              salary: '',
              hire_date: '',
              identy_number: '',
              salary_type: '',
            });
      
            // Optionally, fetch updated data or perform any other actions
            getdata();
          })
          .catch((error) => {
            // Default error message
            let errorMessage = "An error occurred. Please try again later.";
      
            // Check if the error response contains a "message" field
            if (error.response && error.response.data && error.response.data.message) {
              errorMessage = error.response.data.message;
            }
      
            // Show toast notification with the error message
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 1500,
              theme: "colored",
            });
      
            // Print the error message to the console
            console.error(errorMessage);
          });
    };

    const updateData =(item)=>{
        setSideModel(true)
        setFormUpdateData(item)
        setEmployeeUpdateId(item.id);
    }

    const handleUpdate =(data)=>{

        axiosClient.put(`employee/${employeeUpdateId}`, formUpdateData)
        .then((response) => {
            toast.success( `${employeeId} Updated Sucessfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
            getdata();
            setSideModel(false)

            
        })
        .catch((error) => {
            let firstErrorMessage = '';
            
            if (error.response && error.response.data && error.response.data.errors && Object.keys(error.response.data.errors).length !== 0) {
                const errors = error.response.data.errors;
                const errorMessages = Object.values(errors); // Get an array of all error messages
                firstErrorMessage = errorMessages[0][0]; // Get the first error message
                
                toast.error(firstErrorMessage, {
                    position: "top-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            } else {
                toast.error('An unexpected error occurred. Please try again.', {
                    position: "top-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        });
        
    
    }

    const deleteData = (item) => {
        Swal.fire({
        title: "please confirm?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`employee/${item.id}`)
                .then((response) => {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    getdata();
                })
            .catch((error) => {
                toast.error("record not found", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                });
        });
    };
        })
    }

    const columns = useMemo(
        () => [
          {
            Header: "Sr.",
            accessor: (row, index) => index + 1, // Calculate the index
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Contact",
            accessor: "contact_number",
          },
          {
            Header:"Type",
            accessor:"salary_type"
        
          },
          {
            Header:"Role",
            accessor:"position_title"
        
          },
          {
            Header:"Department",
            accessor:"department_title"
        
          },
          {
            Header:"Joining",
            accessor:"hire_date"
        
          },
          {
            Header:"Salary",
            accessor:"salary"
        
          },
          {
            Header: "Action",
            Cell: ({ row }) => (
              <div className="flex">
                <a
                  className="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    updateData(row.original);
                  }}
                >
                  Edit
                </a>
                <a
                  className="warning"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteData(row.original);
                  }}
                >
                  Delete
                </a>
              </div>
            ),
          },
        ],
        []
        );
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
        } = useTable(
        {
          columns,
          data: employeelist,
          initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
        );
    //receive data
    const getdata = () => {
        setIsloading(true);
        axiosClient.get("employee")
            .then((response) => {
                setEmployeelist(response.data);
                // console.log(employeelist);
                setIsloading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const PrintPDF = () => {
        const data = employeelist;
        
        const doc = new jsPDF('landscape', 'pt', 'a4');
        doc.setFontSize(12);
    
        // Metadata for the report
        const hotel = "Visioninn Hotel and Resturant";
        const title = "Employee Details";
        const generatedAt = `Generated At: ${new Date().toLocaleDateString()}`;
    
        // Adding metadata to the PDF
        doc.text(hotel, 40, 30);
        doc.text(title, 40, 50);
        doc.text(generatedAt, 40, 70);

        // Adding the image to the right side of the metadata
        const imgWidth = 50; // Adjust the width of the image
        const imgHeight = 50; // Adjust the height of the image
        const xOffset = 700; // Adjust the x-position to move it to the right
        const yOffset = 20;  // Adjust the y-position if needed

        // Adding the image (visioninn) to the PDF
        doc.addImage(visioninn, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);
    
        // Table headers for employee data
        const headers = [
            "ID", 
            "Name", 
            "Date of Birth", 
            "Gender", 
            "Contact Number", 
            "Email", 
            "Address", 
            "Department", 
            "Position", 
            "Salary", 
            "Hire Date", 
            "Salary Type", 
            "ID Number"
        ];
    
        // Mapping data to rows
        const rows = data.map(employee => [
            employee.id,
            employee.name,
            employee.date_of_birth,
            employee.gender,
            employee.contact_number,
            employee.email,
            employee.address,
            employee.department_title,
            employee.position_title,
            employee.salary,
            employee.hire_date,
            employee.salary_type,
            employee.identy_number
        ]);
    
        // If there are fewer than 10 rows, add empty rows to fill space
        while (rows.length < 10) {
            rows.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);
        }
    
        // Creating the table in the PDF
        doc.autoTable({
            head: [headers],
            body: rows,
            startY: 90,
            theme: 'grid',
            styles: { cellPadding: 5, fontSize: 10, valign: 'middle', halign: 'center' },
            headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
            margin: { top: 80 }
        });
    
        // Check if we need to add another page
        if (data.length > 10) {
            doc.addPage();
            const extraRows = data.slice(10).map(employee => [
                employee.id,
                employee.name,
                employee.date_of_birth,
                employee.gender,
                employee.contact_number,
                employee.email,
                employee.address,
                employee.department_title,
                employee.position_title,
                employee.salary,
                employee.hire_date,
                employee.salary_type,
                employee.identy_number
            ]);
    
            while (extraRows.length < 10) {
                extraRows.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);
            }
    
            doc.autoTable({
                head: [headers],
                body: extraRows,
                startY: 30,
                theme: 'grid',
                styles: { cellPadding: 3, fontSize: 10, valign: 'middle', halign: 'center' },
                headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
                margin: { top: 20 }
            });
        }
    
        // Save the PDF file
        doc.save(`Employee_Report_${new Date().toLocaleDateString('en-GB').replace(/\//g, '')}.pdf`);
    };
    

    const hanldeEdit = (data) => {
    alert(data);
    };
    useEffect(() => {
     getdata();
    }, []);
      

    useEffect(() => {
        // Fetch departments
        axiosClient.get('department')
            .then(response => {
                setDepartments(response.data);
            })
            .catch(error => {
                console.error('Error fetching departments:', error);
                toast.error('Error fetching departments. Please try again later.');
            });

        // Fetch positions
        axiosClient.get('position')
            .then(response => {
                setPositions(response.data);
            })
            .catch(error => {
                console.error('Error fetching positions:', error);
                toast.error('Error fetching positions. Please try again later.');
            });
    }, []); // Run this effect only once on component mount

if (isloading) {
    return <div className="container"><main><h1>Employee List</h1><div className="recent-orders"><LoadingComponent /></div></main></div>;
    }

    return (
    <div className="container">
        <main>
            <h1>Employee List</h1>

            <div className="mt-1 table-search d-flex">
                <input
                type="text"
                placeholder="Search in table..."
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                />
                <div>
                <button
                    className="py-1"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    Previous
                </button>
                <button
                    className="py-1"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    Next
                </button>
                <span className="py-1">
                    Page{" "}
                    <strong>
                    {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <select
                    className="tablePagination"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                >
                    {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                    ))}
                </select>
                </div>
            </div>
            <div className="recent-orders">
                <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                        <th
                            {...column.getHeaderProps(
                            column.getSortByToggleProps()
                            )}
                            className={
                            column.isSorted
                                ? column.isSortedDesc
                                ? "sorted-desc"
                                : "sorted-asc"
                                : ""
                            }
                        >
                            {column.render("Header")}
                            <span>
                            {column.isSorted
                                ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                                : ""}
                            </span>
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                            </td>
                        ))}
                        </tr>
                    );
                    })}
                </tbody>
                </table>
            </div>
            <div className="tablePagination-no">
                {pageOptions.length > 1 &&
                pageOptions.map((_, index) => (
                    <button
                    key={index}
                    onClick={() => gotoPage(index)}
                    className={`tablePagination-btn ${
                        index === pageIndex ? "active" : ""
                    }`}
                    >
                    {index + 1}
                    </button>
                ))}
            </div>
            <button className="save" onClick={PrintPDF}>Print List</button>

        </main>
        <main>
            <div className="" style={{margin:'5.6rem 0 0 0'}}>
                <div className="form">
                    <h2>Add Employee</h2>
                    <div className="entry-one">
                        <label>Name</label>
                        <input type="text" name="name" value={formData.name} onChange={handleChange} required />

                        <label>Date of Birth</label>
                        <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} required />

                        <label>Gender</label>
                        {/* <input type="text" name="gender" value={formData.gender} onChange={handleChange} required /> */}
                        <select name='gender' onChange={handleChange}>
                            <option>--Chose an Option--</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                        </select>
                        <label>Contact Number</label>
                        <input type="text" name="contact_number" value={formData.contact_number} onChange={handleChange} required />

                        <label>Email</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />

                        <label>Address</label>
                        <textarea type="text" name="address" value={formData.address} onChange={handleChange} required />

                        <label>Department</label>
                        <select name="department_id" value={formData.department_id} onChange={handleChange} required>
                            <option value="">--Select Department--</option>
                            {departments.map(department => (
                                <option key={department.id} value={department.id}>{department.department_name}</option>
                            ))}
                        </select>

                        <label>Position</label>
                        <select name="position_id" value={formData.position_id} onChange={handleChange} required>
                            <option value="">--Select Position--</option>
                            {positions.map(position => (
                                <option key={position.id} value={position.id}>{position.position_title}</option>
                            ))}
                        </select>
                        <label>Salary</label>
                        <input type="text" name="salary" value={formData.salary} onChange={handleChange} required />

                        <label>Hire Date</label>
                        <input type="date" name="hire_date" value={formData.hire_date} onChange={handleChange} required />
                        {/* <DatePicker
                            selected={formData.hire_date}
                            onChange={handleChange}
                            dateFormat="dd/MM/yyyy" // Customize the date format if needed
                            placeholderText="Select hire date" // Placeholder text for the input field
                            required
                        /> */}
                        <label>Identity Number</label>
                        <input type="text" name="identy_number" value={formData.identy_number} onChange={handleChange} required />

                        <label>Salary Type</label>
                        {/* <input type="text" name="salary_type" value={formData.salary_type} onChange={handleChange} required /> */}
                        <select name='salary_type' onChange={handleChange}>
                            <option>--Chose an Option--</option>
                            <option value='Hourly'>Hourly</option>
                            <option value='Monthly'>Monthly</option>
                        </select>
                    </div>

                    <button className="save" onClick={hanldeSubmit}>Add Employee</button>
                </div>
            </div>
        </main>

        {sideModel &&
            <div className={`model-side ${sideModel ? "model-side-show" : ""}`}>
                <div className="model-side-closebtn" onClick={() => setSideModel(false)}>
                    <span className="material-symbols-outlined rotate-icon">close</span>
                </div>
                <div className="form">
                    <h2>Edit Employee</h2>
                    <div className="entry-one">
                        <label>Name</label>
                        <input type="text" name="name" value={formUpdateData.name} onChange={handleUpdateChange} required />

                        <label>Date of Birth</label>
                        <input type="date" name="date_of_birth" value={formUpdateData.date_of_birth} onChange={handleUpdateChange} required />

                        <label>Gender</label>
                        {/* <input type="text" name="gender" value={formData.gender} onChange={handleUpdateChange} required /> */}
                        <select name='gender' onChange={handleUpdateChange}>
                            <option>Chose and Option</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                        </select>
                        <label>Contact Number</label>
                        <input type="text" name="contact_number" value={formUpdateData.contact_number} onChange={handleUpdateChange} required />

                        <label>Email</label>
                        <input type="email" name="email" value={formUpdateData.email} onChange={handleUpdateChange} required />

                        <label>Address</label>
                        <textarea type="text" name="address" value={formUpdateData.address} onChange={handleUpdateChange} required />

                        <label>Department</label>
                        <select name="department_id" value={formUpdateData.department_id} onChange={handleUpdateChange} required>
                            <option value="">Select Department</option>
                            {departments.map(department => (
                                <option key={department.id} value={department.id}>{department.department_name}</option>
                            ))}
                        </select>

                        <label>Position</label>
                        <select name="position_id" value={formUpdateData.position_id} onChange={handleUpdateChange} required>
                            <option value="">Select Position</option>
                            {positions.map(position => (
                                <option key={position.id} value={position.id}>{position.position_title}</option>
                            ))}
                        </select>

                        <label>Salary</label>
                        <input type="text" name="salary" value={formUpdateData.salary} onChange={handleUpdateChange} required />

                        <label>Hire Date</label>
                        <input type="date" name="hire_date" value={formUpdateData.hire_date} onChange={handleUpdateChange} required />

                        <label>Identity Number</label>
                        <input type="text" name="identy_number" value={formUpdateData.identy_number} onChange={handleUpdateChange} required />

                        <label>Salary Type</label>
                        {/* <input type="text" name="salary_type" value={formData.salary_type} onChange={handleUpdateChange} required /> */}
                        <select name='salary_type' onChange={handleUpdateChange}>
                            <option>Chose and Option</option>
                            <option value='Hourly'>Hourly</option>
                            <option value='Monthly'>Monthly</option>
                        </select>
                    </div>
                    
                    <button className="save" onClick={handleUpdate}>Update Employee</button>

                </div>
            </div>
        }

    </div>
  )
}

export default NewEmployee
