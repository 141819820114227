// customermodal.js
import React, { useEffect, useState } from "react";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import LoadingComponent from "../Components/LoadingComponent";


const ServiceModal = ({ room, roomNumber, onClose }) => {
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [serviceTitle, setServiceTitle] = useState();
  const [serviceCharges, setServiceCharges] = useState();
  const [serviceDate, setServiceDate] = useState();

  const fetchCustomerDetails = async () => {
    try {
      // Call the API to get customer details by room ID
      const response = await axiosClient.get(`customer/byroom/${room}`);

      // Log the result to the console
    //   console.log("Customer details:", response.data);
      setCustomerData(response.data.data);
      setLoading(false);

      // Show a success toast notification
      toast.success("Customer details retrieved successfully.", {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } catch (error) {
      // Handle errors and show appropriate error messages
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        let errorMessage = "";
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errorMessage += errors[key].join("\n") + "\n";
          }
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
        });
      } else {
        console.error("Error retrieving customer details:", error);
        toast.error(
          "Failed to retrieve customer details. Please try again later.",
          {
            position: "top-right",
            autoClose: 2500,
            theme: "colored",
          }
        );
      }
    }
  };

  const submitServiceCharges = async (e) => {
    e.preventDefault();
    try {
        const payload = {
            room_id: room, 
            service_name: serviceTitle,
            service_charge: serviceCharges,
            service_date: serviceDate,
        };

        const response = await axiosClient.post('service-to-checkins', payload);

        toast.success("Service charges submitted successfully.", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
        });

        onClose();
    } catch (error) {

        if (error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            let errorMessage = "";
            for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    errorMessage += errors[key].join("\n") + "\n";
                }
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 5000,
                theme: "colored",
            });
        } else {
            console.error("Error submitting service charges:", error);
            toast.error(
                "Failed to submit service charges. Please try again later.",
                {
                    position: "top-right",
                    autoClose: 2500,
                    theme: "colored",
                }
            );
        }
    }
};

  useEffect(() => {
    fetchCustomerDetails();
    setLoading(true);
  }, []);

  if (!room) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>Service to Room# {roomNumber}</h2>
        {loading ?
        
        <div className="modal-loading">
            <LoadingComponent />
        </div>
        
        :
        
        <form>
            <div className="form-row full-width">
                <label>Name:</label>
                <input 
                    type="text" 
                    value={customerData?.name || ''} 
                    disabled 
                />
            </div>
            <div className="form-row full-width">
                <label>Service Title:</label>
                <input 
                    type="text"
                    placeholder="Service Name"
                    onChange={(e)=>setServiceTitle(e.target.value)}
                    required
                />
            </div>
            <div className="form-row full-width">
                <label> Service Changes</label>
                <input 
                    type="number" 
                    placeholder="0-999.. any"
                    onChange={(e)=>setServiceCharges(e.target.value)}
                    required
                />
            </div>
            <div className="form-row full-width">
                <label> Service Changes</label>
                <input 
                    type="date" 
                    onChange={(e)=>setServiceDate(e.target.value)}
                    required
                />
            </div>


            <div className="button-group">
                <button type="button success" onClick={submitServiceCharges}>Add Charges</button>
                <button type="button" onClick={onClose}>Close</button>
            </div>
        </form>

        }

      </div>
    </div>
  );
};

export default ServiceModal;
