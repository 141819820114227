import React from "react";
import { useEffect, useState, useMemo } from "react";
import axiosClient from "../axios-client";
import Swal from "sweetalert2";
import { toast } from "react-toastify"
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import visioninn from "../Images/visioninn.png"


const AllDinningBills = () => {
  const [updateId, setUpdateId] = useState();
  const [sideModel, setSideModel] = useState(false);
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [isloading, setIsloading] = useState(true);
  const [showSC, setShowSC] = useState(false);

  const [room, setRoom] = useState();
  const [service, setService] = useState();
  const [serviceCharges, setServiceCharges] = useState();
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState();
  const [uom, setUOM] = useState();
  const [name, setName] = useState();
  const [datalist, setdatalist] = useState([]);
  const [headlist, setHeadlist] = useState([]);
  const [searchID, setSearchID] = useState("");
  const [serviceChargeList, setServiceChargeList] = useState([]);

  const [selectedBooking, setSelectedBooking] = useState([]);
  // const [searchedRoomNo, setSearchRoomNo] = useState('null');

  const [selectedDay, setSelectedDay] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [selectedBill, setSelectedBill] = useState(null);

  const [filterType, setFilterType] = useState(""); // Store the selected filter type
  const [startDate, setStartDate] = useState(""); // Store start date
  const [endDate, setEndDate] = useState("");     // Store end date

  const handleSubmit = (item) => {
    const booking = datalist.find((item) => item.id === parseInt(searchID));
    if (booking) {
      setSelectedBooking(booking);
      console.log(selectedBooking, "booking list");
    }
    setShowSC(false);

    let errorMessage = "";

    // Validate date
    if (!searchID) {
      errorMessage += "Booking ID required.\n";
    }

    // Create payload object with expense data
    const payload = {
      booking_id: searchID,
    };

    // Send POST request to backend to add expense
    axiosClient
      .post(`service-to-checkins/${searchID}`, payload)
      .then((response) => {
        setServiceChargeList(response.data);
        setSearchID();
        setShowSC(true);
        toast.success(`Record founded successfully.`, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });

        // Clear form fields after successful submission
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const errors = error.response.data.errors;
          let errorMessage = "";
          errors.forEach((err) => {
            errorMessage += err + "\n";
          });
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
          });
        } else {
          console.error("Error adding expense:", error);
          toast.error("An unknown error occurred", {
            position: "top-right",
            autoClose: 2500,
            theme: "colored",
          });
        }
      });
  };

  const AddService = (item) => {
    setSideModel(true);
    setUpdateId(item.id);
    setName(item.customer.name);
    setRoom(item.room.room_number);
    setDescription(item.description);
    setAmount(item.price);
    setUOM(item.unit_of_measurement);
    setQuantity(item.quantity);
  };
  const handleService = (data) => {
    setSideModel(false);

    const payload = {
      booking_id: updateId,
      service_name: service,
      service_charge: serviceCharges,
      service_date: selectedDay,
    };

    axiosClient
      .post(`service-to-checkins`, payload)
      .then((response) => {
        toast.success(`Menu Item Updated Sucessfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setUpdateId();
        setService();
        setServiceCharges();
        setSelectedDay();
      })
      .catch((err) => {
        console.log(err);
        var firstErrorMessage = "";
        if (
          err.response.data.error &&
          Object.keys(err.response.data.error).length != 0
        ) {
          var errors = err.response.data.error;
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
      });
  };
  const deleteItem = (item) => {
    Swal.fire({
      title: "please confirm?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete(`weekly-menu/${item.id}`)
          .then((response) => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getdata();
          })
          .catch((error) => {
            toast.error("record not found", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    });
  };

  // Define columns using useMemo or directly
  const columns = useMemo(
    () => [
      {
        Header: "Sr.",
        accessor: (row, index) => index + 1, // Calculate the index
      },
      {
        Header: "Booking ID",
        accessor: "id",
      },
      {
        Header: "Customer",
        accessor: (row) =>
          row.customer_name || row.walk_in_customer_name || "Walk-in",
      },
      {
        Header: "Bill Date",
        accessor: "bill_date",
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
        Cell: ({ value }) => <b>{value}</b>,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <div className="flex">
            <a
              className="primary"
              onClick={(e) => {
                e.preventDefault();
                handleViewDetails(row.original);
              }}
            >
              View Details
            </a>

            <a
              className="warning"
              onClick={(e) => {
                e.preventDefault();
                handleDelete(row.original.id);
              }}
            >
              Delete
            </a>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: datalist,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const getdata = () => {
    axiosClient
      .get("bills")
      .then((response) => {
        setdatalist(response.data.bills);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getexpensedata = () => {
    axiosClient
      .get("menuitems")
      .then((response) => {
        setMenuItems(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewDetails = (bill) => {
    setSelectedBill(bill);
    setSideModel(true);
  };

  const handleDelete = (bill) => {
    Swal.fire({
      title: "please confirm?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete(`bills/${bill}`)
          .then((response) => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getdata();
          })
          .catch((error) => {
            toast.error("record not found", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const printBill = () => {
    const doc = new jsPDF("portrait", "pt", "a4");

    // Sample data
    const hotelName = "Visioninn Hotel";
    const hotelContact = "Contact: 123-456-7890";
    const hotelAddress = "123 Hotel St, City, Country";
    const logoUrl = visioninn; // Replace with your hotel logo

    const billDate = formatDate(selectedBill.bill_date); // Formatted Bill date
    const customerName =
      selectedBill.customer_name ||
      selectedBill.walk_in_customer_name ||
      "Walk-In Customer";

    // PDF Filename: Format as "YYYY-MM-DD_CustomerName.pdf"
    const pdfFileName = `${billDate}_${customerName.replace(/\s/g, "_")}.pdf`;

    // Helper function to print single bill
    const printSingleBill = (xOffset) => {
      // Hotel Info - Name, Logo, Contact (smaller font and adjusted width)
      doc.setFontSize(10);
      doc.text(hotelName, 10 + xOffset, 40);
      doc.addImage(logoUrl, "PNG", 250 + xOffset, 20, 30, 30); // Adjust logo size and placement
      doc.setFontSize(8);
      doc.text(hotelContact, 10 + xOffset, 60);
      doc.text(hotelAddress, 10 + xOffset, 75);

      doc.setFontSize(10);
      // Bill Details - Customer, Date, Total Amount (smaller font)
      doc.text(`Customer: ${customerName}`, 10 + xOffset, 105);
      doc.text(`Bill Date: ${billDate}`, 10 + xOffset, 120);

      // Table of Items
      const headers = [["Item Name", "Qty", "Unit Price", "Subtotal"]];
      const data = selectedBill.items.map((item) => [
        item.name,
        item.quantity,
        item.unit_price,
        item.subtotal,
      ]);

      doc.autoTable({
        startY: 150,
        head: headers,
        body: data,
        margin: { left: 20 + xOffset }, // Offset to align the table for side-by-side copies
        styles: {
          fontSize: 8, // Smaller font size for table
          cellPadding: 2, // Reduce cell padding
          lineColor: [0, 0, 0], // Black borders (no color)
          lineWidth: 0.1, // Thin borders
        },
        tableWidth: 250, // Ensure tables fit side by side
      });

      // Total Amount (in smaller font size)
      const finalY = doc.lastAutoTable.finalY + 20;
      doc.text(
        `Total Amount: ${selectedBill.total_amount}`,
        10 + xOffset,
        finalY
      );
    };

    // Print two copies on the same page, side by side
    printSingleBill(0); // First copy (left side)
    printSingleBill(300); // Second copy (right side)

    // Save the PDF with the formatted filename
    doc.save(pdfFileName);
  };

  // all bills print
  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    setStartDate(""); // Reset dates when the filter type changes
    setEndDate("");
  };

  const getFilteredBills = () => {
    let filteredBills = datalist;
  
    if (!datalist || filteredBills.length === 0) {
      alert("No bills found for the selected date range!");
      return [];
    }
  
    // Filter by today's bills
    if (filterType === "daily") {
      const today = new Date().toISOString().split("T")[0];
      filteredBills = datalist.filter((bill) => bill.bill_date === today);
    }
  
    // Filter by date range
    if (filterType === "daterange" && startDate && endDate) {
      filteredBills = datalist.filter(
        (bill) => bill.bill_date >= startDate && bill.bill_date <= endDate
      );
    }
  
    return filteredBills;
  };
  

  // PDF generation for filtered bills
  const handlePrintFiltered = () => {
    const filteredBills = getFilteredBills();
    
    // Check if filtered bills list is empty
    if (filteredBills.length === 0) {
      alert("No bills found for the selected date range!");
      return; // Do not proceed with PDF generation
    }

    generatePDF(filteredBills, filterType, startDate, endDate);
  };

  // PDF generation for all bills
  const handlePrintAll = () => {
    if (datalist.length === 0) {
      alert("No bills available to print!");
      return;
    }
    generatePDF(datalist);
  };

  // Function to generate PDF using jsPDF
  const generatePDF = (bills, filterType, startDate = "", endDate = "") => {
    const doc = new jsPDF();
  
    // Hotel information and logo details
    const hotelName = "Visioninn Hotel";
    const hotelContact = "Contact: 123-456-7890";
    const hotelAddress = "123 Hotel St, City, Country";
    const logoUrl = visioninn; // Replace with your hotel logo or path to the image
  
    // Get the current date and time in the desired format
    const currentDate = new Date();
    const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}${String(currentDate.getMonth() + 1).padStart(2, '0')}${String(currentDate.getFullYear()).slice(-2)}`; // Format: DDMMYY
    const formattedTime = currentDate.toLocaleTimeString(); // Format: HH:MM:SS AM/PM
  
    // Load the logo image (if available)
    const imgWidth = 20; // Set width for the logo image
    const imgHeight = 20; // Set height for the logo image
    if (logoUrl) {
      doc.addImage(logoUrl, "PNG", 150, 10, imgWidth, imgHeight);
    }
  
    // Add hotel information on the right side
    doc.setFontSize(12);
    doc.text(hotelName, 15, 15, { align: "left" }); // Hotel name
    doc.text(hotelContact, 15, 20, { align: "left" }); // Contact information
    doc.text(hotelAddress, 15, 25, { align: "left" }); // Address
  
    // Add the date and time below the hotel information
    doc.setFontSize(10);
    doc.text(`Date: ${formattedDate}`, 15, 30, { align: "left" }); // Date in DDMMYY format
    doc.text(`Time: ${formattedTime}`, 15, 35, { align: "left" }); // Time
  
    // Add title based on the filter type
    doc.setFontSize(14);
    let filterHeading = "";
    
    // Determine heading based on the filterType
    switch (filterType) {
      case "daily":
        filterHeading = `Today’s Bills - Date: ${formattedDate}`;
        break;
      case "daterange":
        filterHeading = `Bills from ${startDate} to ${endDate}`;
        break;
      case "all":
      default:
        filterHeading = "All Bills";
        break;
    }
  
    // Display the heading at a specific position
    doc.text(filterHeading, 14, 45); // Positioning the heading
  
    // Define table columns
    const columns = [
      { header: "Bill ID", dataKey: "id" },
      { header: "Customer Name", dataKey: "customer_name" },
      { header: "Walk-in Customer Name", dataKey: "walk_in_customer_name" },
      { header: "Bill Date", dataKey: "bill_date" },
      { header: "Total Amount", dataKey: "total_amount" },
    ];
  
    // Format the bills data for PDF
    const rows = bills.map((bill) => ({
      id: bill.id,
      customer_name: bill.customer_name || "N/A",
      walk_in_customer_name: bill.walk_in_customer_name || "N/A",
      bill_date: bill.bill_date,
      total_amount: bill.total_amount,
    }));
  
    // Use jsPDF AutoTable plugin to generate the table
    doc.autoTable({
      startY: 55, // Start the table below the header and date/time
      head: [columns.map((col) => col.header)],
      body: rows.map((row) => Object.values(row)),
    });
  
    // Save the PDF with a file name that includes the formatted date
    const fileName = `bills_report_${formattedDate}.pdf`;
    doc.save(fileName);
  };
  
  

  useEffect(() => {
    getdata();
    getexpensedata();
  }, []);

  if (isloading) {
    return (
      <div className="containerfull">
        <main>
          <h1>All Bills</h1>
          <div className="recent-orders">
            <LoadingComponent />
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="containerfull">
      <main>
        <h1>All Bills</h1>
        <div className="mt-1 table-search d-flex">
          <input
            type="text"
            placeholder="Search in table..."
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <div>
            <button
              className="py-1"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="py-1"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <span className="py-1">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <select
              className="tablePagination"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="recent-orders">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sorted-desc"
                            : "sorted-asc"
                          : ""
                      }
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tablePagination-no">
          {pageOptions.length > 1 &&
            pageOptions.map((_, index) => (
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={`tablePagination-btn ${
                  index === pageIndex ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
        </div>

        <div className="entry-four mt-2">
          <label>Print By Date: </label>
          <select 
            value={filterType} 
            onChange={handleFilterTypeChange} 
            className="form-control mx-2"
          >
            <option value="">--Select Filter--</option>
            <option value="daily">Today’s Bills</option>
            <option value="daterange">Date Range</option>
            <option value="alldates">All Bills</option>
          </select>

          {/* Conditional Date Range Inputs */}
          {filterType === "daterange" && (
            <>
              <label>Start Date: </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="form-control mx-2"
              />
              <label>End Date: </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="form-control mx-2"
              />
            </>
          )}
        </div>

        {/* Print Buttons */}
        <div className="d-flex mt-2">
          {/* Conditionally Render Print Filtered Button */}
          {filterType && (
            <button className="save" onClick={handlePrintFiltered}>
              Print Filtered List
            </button>
          )}
        </div>

      </main>

      <main>
        {showSC && (
          <div className="form mt-2">
            <div className="entry-show">
              <p>Customer:</p>
              <h5>
                {selectedBooking.customer && selectedBooking.customer.name}
              </h5>
              <p>Room:</p>
              <h5>
                {selectedBooking.room && selectedBooking.room.room_number}
              </h5>
              {serviceChargeList.map((item) => (
                <>
                  <h5>{item.service_name}</h5>
                  <h5>{item.service_charge}</h5>
                </>
              ))}
            </div>
          </div>
        )}
      </main>
      {sideModel && selectedBill && (
        <div className={`model-side ${sideModel ? "model-side-show" : ""}`}>
          <div
            className="model-side-closebtn"
            onClick={() => setSideModel(false)}
          >
            <span className="material-symbols-outlined rotate-icon">close</span>
          </div>
          <div className="form">
            <h2>Bill Details</h2>
            <div className="entry-one">
              <label>Customer</label>
              <input
                type="text"
                value={
                  selectedBill.customer_name ||
                  selectedBill.walk_in_customer_name ||
                  "Walk-In Customer"
                }
                disabled
              />
              <label>Bill Date</label>
              <input type="text" value={selectedBill.bill_date} disabled />
              <label>Total Amount</label>
              <input type="text" value={selectedBill.total_amount} disabled />
            </div>
            <div className="entry-block">
              <h2>Items</h2>
              <table>
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedBill.items.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.unit_price}</td>
                      <td>{item.subtotal}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className="save" onClick={printBill}>
                Print Bill
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AllDinningBills