import React from "react";
import { useEffect, useState,useMemo } from "react";
import axiosClient from "../axios-client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify"
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";

const InventoryHead = () => {
    const [updateId, setUpdateId] = useState();
    const [sideModel, setSideModel] = useState(false)
    const [isloading, setIsloading] = useState(false)
    const [roomNumber, setRoomNumber] = useState();
    const [block, setBlock] = useState();
    const [floor, setFloor] = useState();
    const [type, setType] = useState();
    const [product_name, setProduct_name] = useState();
    const [quantity, setQuantity] = useState();
    const [price, setPrice] = useState();
    const [datalist, setDatalist] = useState([]);

    const hanldeSubmit = () => {

        let errorMessage = '';

        // Check each required field
        if (!roomNumber) {
            errorMessage += "head is required.\n";
        }

        // If any required field is missing, show error message
        if (errorMessage) {
            toast.error(errorMessage.trim(), { // trim() removes trailing whitespace
                position: "top-right",
                autoClose: 2500,
                theme: "colored",
            });
            return;
        }

        let payload = {
            title: roomNumber,
            description: block,

        };
        axiosClient.post("inventoryhead", payload)
            .then((response) => {
                toast.success(`${roomNumber} Added Sucessfully`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // Clear fields after successful submission
                setRoomNumber(''); // Clear roomNumber state
                setBlock(''); // Clear block state
                setFloor(''); // Clear floor state
                setType(''); // Clear type state
                getdata();
            })
            .catch((err) => {
                console.log(err)
                var firstErrorMessage = '';
                if (err.response.data.error && Object.keys(err.response.data.error).length != 0) {
                    var errors = err.response.data.error
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            });
    };

    const updateCity = (item) => {
        setSideModel(true)
        setUpdateId(item.id)
        setProduct_name(item.title);
        setQuantity(item.description);
    }
    const handleUpdate = (data) => {
        setSideModel(false)

        let payload = {
            title: product_name,
            description: quantity,
        };
        axiosClient.put(`inventoryhead/${updateId}`, payload)
            .then((response) => {
                toast.success(`${product_name} Updated Sucessfully`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                getdata();
                setProduct_name('');
                setQuantity('');
            })
            .catch((err) => {
                console.log(err)
                var firstErrorMessage = '';
                if (err.response.data.error && Object.keys(err.response.data.error).length != 0) {
                    var errors = err.response.data.error
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            });

    }



    const deleteCity = (item) => {
        Swal.fire({
            title: "please confirm?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`inventoryhead/${item.id}`)
                    .then((response) => {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        getdata();
                    })
                    .catch((error) => {
                        toast.error("record not found", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    });
            };
        })
    }
    const columns = useMemo(
        () => [
          {
            Header: "Sr.",
            accessor: (row, index) => index + 1, // Calculate the index
          },
          {
            Header: "name",
            accessor: "title",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Created AT",
            accessor: "created_at",
            Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
          },
          {
            Header: "Updated AT",
            accessor: "updated_at",
            Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
          },
          {
            Header: "Action",
            Cell: ({ row }) => (
              <div className="flex">
                <a
                  className="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    updateCity(row.original);
                  }}
                >
                  Edit
                </a>
                <a
                  className="warning"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteCity(row.original);
                  }}
                >
                  Delete
                </a>
              </div>
            ),
          },
        ],
        []
        );
        
        const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
        } = useTable(
        {
          columns,
          data: datalist,
          initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
        );
    //receive data
    const getdata = () => {
        setIsloading(true)
        axiosClient
            .get("inventoryhead")
            .then((response) => {
                setDatalist(response.data);
                setIsloading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    };
 

    useEffect(() => {
        getdata();
    }, []);


    if (isloading) {
        return <div className="container"><main><h1>Inventory Head</h1><div className="recent-orders"><LoadingComponent /></div></main></div>;
      }
    
      return (
        <div className="container">
          <main>
            <h1>Inventory Head</h1>
            <div className="mt-1 table-search d-flex">
              <input
                type="text"
                placeholder="Search in table..."
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <div>
                <button
                  className="py-1"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="py-1"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <span className="py-1">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <select
                  className="tablePagination"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="recent-orders">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sorted-desc"
                                : "sorted-asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="tablePagination-no">
              {pageOptions.length > 1 &&
                pageOptions.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => gotoPage(index)}
                    className={`tablePagination-btn ${
                      index === pageIndex ? "active" : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
            </div>
          </main>
            <main>
                <div className="" style={{ margin: '5.6rem 0 0 0', display: 'flex', justifyContent: 'center' }}>
                    <div className="form">
                        <h2>Add Inventory Head</h2>
                        <div className="entry-block">
                            <label>Title of Head</label>
                            <input type="text" value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} required />

                            <label>Description</label>
                            <input type="text" value={block} onChange={(e) => setBlock(e.target.value)} />

                        </div>
                        <button className="save" onClick={hanldeSubmit}>Add New Head</button>
                    </div>
                </div>
            </main>
            {sideModel &&

                <div className={`model-side ${sideModel ? "model-side-show" : ""}`}>
                    <div className="model-side-closebtn" onClick={() => setSideModel(false)}>
                        <span className="material-symbols-outlined rotate-icon">close</span>
                    </div>
                    <div className="form">
                        <h2>Update Head</h2>
                        <div className="entry-block">
                            <label>Title</label>
                            <input type="text" value={product_name} onChange={(e) => setProduct_name(e.target.value)} required />

                            <label>Quantity</label>
                            <input type="text" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
                        </div>
                        <button className="save" onClick={handleUpdate}>Update Head</button>
                    </div>
                </div>
            }
        </div>
    );
};

export default InventoryHead
