import React, { useState, useEffect } from 'react'
import axiosClient from '../axios-client';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import BookingModal from './BookingModal';
import CustomerModal from './CustomerModal';
import ServiceModal from './ServiceModal';

const Home = () => {
    const[number, setNumber] =useState(1);
    const[total, setTotalNumber] =useState(1);
    const[roomlist, setRoomlist] =useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [customerSearchIdByRoom, setCustomerSearchIdByRoom] =useState();
    const [roomNumberPassing, setRoomNumberPassing] =useState();

    const handleBookRoomClick = (e, room) => {
        e.preventDefault(); // Prevent the default action of the link
        setSelectedRoom(room); // Pass the room object to the state
        console.log(room); // This will log the room object
        setIsModalOpen(true); // Open the modal
    };

    const handleCustomerDetails = (e, room) => {
        e.preventDefault();
        setCustomerSearchIdByRoom(room.id);
        setRoomNumberPassing(room.room_number);
        setIsCustomerModalOpen(true);
    }

    const handleService =(e, room) => {
        e.preventDefault();
        setCustomerSearchIdByRoom(room.id);
        setRoomNumberPassing(room.room_number);
        setIsServiceModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setIsCustomerModalOpen(false);
        setIsServiceModalOpen(false);
        setSelectedRoom(null);
    };

    const getStatusTooltip = (status) => {
        switch (status) {
            case 'available':
                return 'Available';
            case 'booked':
                return 'Booked';
            case 'maintenance':
                return 'Under Maintenance';
            default:
                return '';
        }
    };

      //receive data
    const getRoomStatus = () => {
        axiosClient.get("rooms")
            .then((response) => {
                setRoomlist(response.data);
                setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
        });
    };
    
    useEffect(() => {
        getRoomStatus();
    }, []);

    if (isLoading) {
        return (
            <div className="loader">
                <ThreeDots
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        );
    }

  return (
    <div className="containerfull">

    <main>
         <h1>Dashboard</h1>

         <div className="insights">
            {roomlist.map((room, index) => (
                <div className={`room ${room.status}`} key={index} title={getStatusTooltip(room.status)}>
                    <div className="sales">
                        <div className="d-flex">
                            <span className="material-symbols-outlined">bed</span>
                            <h2>{room.room_number}</h2>
                        </div>
                        <div className="middle mt-1">
                            <div className="left">
                                <p>Type: {room.type}</p>
                                <p>Block: {room.block}</p>
                                <p>Floor: {room.floor}</p>
                                <p>Status: {room.status}</p>
                            </div>
                            <div className="actionList">
                                {room.status === 'booked' ? (
                                    <div className="actions">
                                        <Link 
                                            onClick={(e) => handleCustomerDetails(e, room)}
                                            className="action-link">Customer Details</Link>
                                        <Link 
                                            to={`/dinning_bill?room=${room.room_number}`} 
                                            className="action-link">
                                            Dinning Bill
                                        </Link>
                                        <Link
                                            onClick={(e) => handleService(e, room)}
                                            to={`/servicetocheckin`} className="action-link">
                                            Add Service
                                        </Link>
                                        <Link
                                            to={`/customercheckout?room=${room.room_number}`}
                                            className="action-link">
                                            Checkout
                                        </Link>
                                    </div>
                                ) : room.status === 'available' ? (
                                    <div className="actions">
                                        <Link 
                                            to={{
                                                pathname: '/newroom',
                                                state: { dashRoomId: room.id },
                                            }} 
                                            className="action-link">
                                            Change Room Status
                                        </Link>
                                        {/* Uncomment if you want to add this option */}
                                        <Link onClick={(e) => handleBookRoomClick(e, room)} className="action-link">Book Room</Link>
                                    </div>
                                ) : (
                                    <div className="actions">
                                        <Link 
                                            to={{
                                                pathname: '/newroom',
                                                state: { dashRoomId: room.id },
                                            }} 
                                            className="action-link">
                                            Change Room Status
                                        </Link>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            ))}
        </div>

    </main>

    {isModalOpen && 
        <BookingModal room={selectedRoom} onClose={closeModal} />
    }

    {isCustomerModalOpen &&
        <CustomerModal room={customerSearchIdByRoom} roomNumber={roomNumberPassing} onClose={closeModal} />
    }

    {isServiceModalOpen &&
        <ServiceModal room={customerSearchIdByRoom} roomNumber={roomNumberPassing} onClose={closeModal} />
    }

    </div>
  )
}

export default Home