// customermodal.js
import React, { useEffect, useState } from "react";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import LoadingComponent from "../Components/LoadingComponent";


const CustomerModal = ({ room, roomNumber, onClose }) => {
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(null);

  const fetchCustomerDetails = async () => {
    try {
      // Call the API to get customer details by room ID
      const response = await axiosClient.get(`customer/byroom/${room}`);

      // Log the result to the console
    //   console.log("Customer details:", response.data);
      setCustomerData(response.data.data);
      setLoading(false);

      // Show a success toast notification
      toast.success("Customer details retrieved successfully.", {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } catch (error) {
      // Handle errors and show appropriate error messages
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        let errorMessage = "";
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errorMessage += errors[key].join("\n") + "\n";
          }
        }
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          theme: "colored",
        });
      } else {
        console.error("Error retrieving customer details:", error);
        toast.error(
          "Failed to retrieve customer details. Please try again later.",
          {
            position: "top-right",
            autoClose: 2500,
            theme: "colored",
          }
        );
      }
    }
  };

  useEffect(() => {
    fetchCustomerDetails();
    setLoading(true);
  }, []);

  if (!room) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>Customer Info Room: {roomNumber}</h2>
        {loading ?
        
        <div className="modal-loading">
            <LoadingComponent />
        </div>
        
        :
        
        <form>
            <div className="form-row">
                <label>Name:</label>
                <input 
                    type="text" 
                    value={customerData?.name || ''} 
                    disabled 
                />
            </div>
            <div className="form-row">
                <label>Father's Name:</label>
                <input 
                    type="text" 
                    value={customerData?.father_name || ''} 
                    disabled 
                />
            </div>
            <div className="form-row">
                <label>CNIC Number:</label>
                <input 
                    type="text" 
                    value={customerData?.cnic_number || ''} 
                    disabled 
                />
            </div>
            <div className="form-row">
                <label>Contact Number:</label>
                <input 
                    type="text" 
                    value={customerData?.contact_number || ''} 
                    disabled 
                />
            </div>
            <div className="form-row">
                <label>Passport Number:</label>
                <input 
                    type="text" 
                    value={customerData?.passport_number || 'N/A'} 
                    disabled 
                />
            </div>
            <div className="form-row">
                <label>Place of Issue:</label>
                <input 
                    type="text" 
                    value={customerData?.place_of_issue || 'N/A'} 
                    disabled 
                />
            </div>
            <div className="form-row">
                <label>Date of Issue:</label>
                <input 
                    type="date" 
                    value={customerData?.date_of_issue || ''} 
                    disabled 
                />
            </div>
            <div className="form-row full-width">
                <label>Complete Address:</label>
                <textarea 
                    value={customerData?.complete_address || ''} 
                    disabled 
                />
            </div>
            <div className="form-row full-width">
                <label>Status</label>
                <input 
                    value={customerData?.status || ''} 
                    disabled 
                />
            </div>

            <div className="button-group">
                <button type="button" onClick={onClose}>Close</button>
            </div>
        </form>

        }

      </div>
    </div>
  );
};

export default CustomerModal;
