import React from "react";
import { useEffect, useState, useMemo } from "react";
import axiosClient from "../axios-client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";

const CustomerList = () => {
    const [updateId, setUpdateId] = useState();
    const [sideModel, setSideModel] = useState(false);
    const [isloading, setIsloading] = useState(false);

    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [expenseHeadId, setExpenseHeadId] = useState('');
    const [name, setName] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [cnicNumber, setCnicNumber] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [placeOfIssue, setPlaceOfIssue] = useState('');
    const [dateOfIssue, setDateOfIssue] = useState('');
    const [completeAddress, setCompleteAddress] = useState('');
    const [bookingstatus, setBookingStatus] = useState('');
    
    const [product_name, setProduct_name] = useState();
    const [quantity, setQuantity] = useState();
    const [datalist, setDatalist] = useState([]);
    const [headlist, setHeadlist] = useState([]);

    const clearDataValues = () =>{
        setName('');
        setFatherName('');
        setCnicNumber('');
        setContactNumber('');
        setPassportNumber('');
        setDateOfIssue('');
        setCompleteAddress('');
        setPlaceOfIssue('');
        setBookingStatus('');
    }

    const hanldeSubmit = () => {
        let errorMessage = '';
    
        // Validate date
        if (!name) {
            errorMessage += "name is required.\n";
        }
    
        // Validate title
        if (!fatherName) {
            errorMessage += "father is required.\n";
        }
    
        // Validate expense head
        if (!contactNumber) {
            errorMessage += "contact Head is required.\n";
        }
    
        // If any validation fails, show error message and return
        if (errorMessage) {
            toast.error(errorMessage.trim(), {
                position: "top-right",
                autoClose: 2500,
                theme: "colored",
            });
            return;
        }
    
        // Create payload object with expense data
        const payload = {
            name: name,
            father_name: fatherName,
            cnic_number: cnicNumber,
            contact_number:contactNumber,
            passport_number: passportNumber,
            place_of_issue:placeOfIssue,
            date_of_issue: dateOfIssue,
            complete_address: completeAddress,
            status: bookingstatus,
        };
    
        // Send POST request to backend to add expense
        axiosClient.post("customer", payload)
            .then((response) => {
                // Handle success
                toast.success(`${name} added successfully.`, {
                    position: "top-right",
                    autoClose: 2000,
                    theme: "colored",
                });

                getdata();
                // Clear form fields after successful submission
                clearDataValues();

            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    let errorMessage = '';
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            errorMessage += errors[key].join('\n') + '\n';
                        }
                    }
                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                } else {
                    console.error("Error adding expense:", error);
                    toast.error("Failed to add expense. Please try again later.", {
                        position: "top-right",
                        autoClose: 2500,
                        theme: "colored",
                    });
                }
            });
    };
    
    const updateData = (item) => {
        setSideModel(true)
        setUpdateId(item.id)
        setName(item.name);
        setFatherName(item.father_name);
        setCnicNumber(item.cnic_number);
        setContactNumber(item.contact_number);
        setPassportNumber(item.passport_number);
        setPlaceOfIssue(item.place_of_issue);
        setDateOfIssue(item.date_of_issue);
        setCompleteAddress(item.complete_address);
        setBookingStatus(item.status);
    }
    const handleUpdate = (data) => {
        setSideModel(false)

        let payload = {
            name: name,
            father_name: fatherName,
            cnic_number: cnicNumber,
            contact_number:contactNumber,
            passport_number: passportNumber,
            place_of_issue:placeOfIssue,
            date_of_issue: dateOfIssue,
            complete_address: completeAddress,
            status: bookingstatus,
        };
        axiosClient.put(`customer/${updateId}`, payload)
            .then((response) => {
                toast.success(`${name} Updated Sucessfully`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                getdata();
                clearDataValues();
            })
            .catch((err) => {
                console.log(err)
                var firstErrorMessage = '';
                if (err.response.data.error && Object.keys(err.response.data.error).length != 0) {
                    var errors = err.response.data.error
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            });

    }
    const deleteCity = (item) => {
        Swal.fire({
            title: "please confirm?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`customer/${item.id}`)
                    .then((response) => {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        getdata();
                    })
                    .catch((error) => {
                        toast.error("record not found", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    });
            };
        })
    }

    const columns = useMemo(
      () => [
        {
          Header: "Sr.",
          accessor: (row, index) => index + 1, // Calculate the index
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Father",
          accessor: "father_name",
        },
        {
          Header: "Contact",
          accessor: "contact_number",
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          Header: "Created AT",
          accessor: "created_at",
          Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
        },
        {
          Header: "Updated AT",
          accessor: "updated_at",
          Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
        },
        {
          Header: "Action",
          Cell: ({ row }) => (
            <div className="flex">
              <a
                className="primary"
                onClick={(e) => {
                  e.preventDefault();
                  updateData(row.original);
                }}
              >
                Edit
              </a>
              <a
                className="warning"
                onClick={(e) => {
                  e.preventDefault();
                  deleteCity(row.original);
                }}
              >
                Delete
              </a>
            </div>
          ),
        },
      ],
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data: datalist,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    

    const getdata = () => {
        setIsloading(true);
        axiosClient.get("customer")
            .then((response) => {
                setDatalist(response.data);
                setIsloading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    
    const getexpensedata = () => {
        axiosClient.get("expensehead")
            .then((response) => {
                setHeadlist(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getdata();
        getexpensedata();
    }, []);

      if (isloading) {
        return <div className="container"><main><h1>Customer List</h1><div className="recent-orders"><LoadingComponent /></div></main></div>;
      }
    
      return (
        <div className="container">
          <main>
            <h1>Customer List</h1>
            <div className="mt-1 table-search d-flex">
              <input
                type="text"
                placeholder="Search in table..."
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <div>
                <button
                  className="py-1"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="py-1"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <span className="py-1">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <select
                  className="tablePagination"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="recent-orders">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "sorted-desc"
                                : "sorted-asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="tablePagination-no">
              {pageOptions.length > 1 &&
                pageOptions.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => gotoPage(index)}
                    className={`tablePagination-btn ${
                      index === pageIndex ? "active" : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
            </div>
          </main>
          <main>
            <div
              className=""
              style={{
                margin: "5.6rem 0 0 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="form">
                <h2>New Customer</h2>
                <div className="entry-one">
                  <label>Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />

                  <label>Father Name</label>
                  <input
                    type="text"
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    required
                  />

                  <label>NIC</label>
                  <input
                    type="text"
                    value={cnicNumber}
                    onChange={(e) => setCnicNumber(e.target.value)}
                  />

                  <label>Contact</label>
                  <input
                    type="text"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    required
                  />

                  <label>Passport No.</label>
                  <input
                    type="text"
                    value={passportNumber}
                    onChange={(e) => setPassportNumber(e.target.value)}
                  />

                  <label>Place of Issue</label>
                  <input
                    type="text"
                    value={placeOfIssue}
                    onChange={(e) => setPlaceOfIssue(e.target.value)}
                  />

                  <label>Date of Issue</label>
                  <input
                    type="date"
                    value={dateOfIssue}
                    onChange={(e) => setDateOfIssue(e.target.value)}
                  />

                  <label>Complete Address</label>
                  <textarea
                    value={completeAddress}
                    onChange={(e) => setCompleteAddress(e.target.value)}
                    required
                  />

                  <label>Status</label>
                  <select onChange={(e) => setBookingStatus(e.target.value)}>
                    <option value="">Select Value</option>
                    <option value="inquiry">Inquiry</option>
                    <option value="booked">Booked</option>
                    <option value="checked-out">Checked Out</option>
                  </select>
                </div>

                <button className="save" onClick={hanldeSubmit}>
                  Add Customer
                </button>
              </div>
            </div>
          </main>
          {sideModel && (
            <div className={`model-side ${sideModel ? "model-side-show" : ""}`}>
              <div
                className="model-side-closebtn"
                onClick={() => {
                  setSideModel(false);
                  clearDataValues();
                }}
              >
                <span className="material-symbols-outlined rotate-icon">
                  close
                </span>
              </div>
              <div className="form">
                <h2>Update Item</h2>
                <div className="entry-one">
                  <label>Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />

                  <label>Father Name</label>
                  <input
                    type="text"
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                    required
                  />

                  <label>NIC</label>
                  <input
                    type="text"
                    value={cnicNumber}
                    onChange={(e) => setCnicNumber(e.target.value)}
                  />

                  <label>Contact</label>
                  <input
                    type="text"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    required
                  />

                  <label>Passport No.</label>
                  <input
                    type="text"
                    value={passportNumber}
                    onChange={(e) => setPassportNumber(e.target.value)}
                  />

                  <label>Place of Issue</label>
                  <input
                    type="text"
                    value={placeOfIssue}
                    onChange={(e) => setPlaceOfIssue(e.target.value)}
                  />

                  <label>Date of Issue</label>
                  <input
                    type="date"
                    value={dateOfIssue}
                    onChange={(e) => setDateOfIssue(e.target.value)}
                  />

                  <label>Complete Address</label>
                  <textarea
                    value={completeAddress}
                    onChange={(e) => setCompleteAddress(e.target.value)}
                    required
                  />

                  <label>Status</label>
                  <select onChange={(e) => setBookingStatus(e.target.value)}>
                    <option value="">Select Value</option>
                    <option value="inquiry">Inquiry</option>
                    <option value="booked">Booked</option>
                    <option value="checked-out">Checked Out</option>
                  </select>
                </div>

                <button className="save" onClick={handleUpdate}>
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      );
};

export default CustomerList