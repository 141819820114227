import axios from 'axios'
const axiosClient = axios.create({
    //offline
    // baseURL: 'http://127.0.0.1:8000/api'
    //online
    baseURL: 'https://visioninnhotel.com/api' 
    //env variables
    // baseURL: process.env.REACT_APP_API_URL
})

axiosClient.interceptors.request.use((config) =>{
    const token = localStorage.getItem('ACCESS_TOKEN')
    config.headers.Authorization = `Bearer ${token}`
    return config;
})

axiosClient.interceptors.response.use((response) =>{
    return response;
}, (error)=>{
    const {response} = error;
    if(response.status === 401){
        localStorage.removeItem('ACCESS_TOKEN')

    }
    throw error;
})

export default axiosClient;