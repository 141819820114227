import React from "react";
import { useEffect, useState } from "react";
import axiosClient from "../axios-client";
import Swal from "sweetalert2";
import { toast } from "react-toastify"
import Loader from "../Components/Loader.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DailyDinningReport = () => {
    const [updateId, setUpdateId] = useState();
    const [sideModel, setSideModel] = useState(false)
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showSC, setShowSC] = useState(false);

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [dataList, setDataList] = useState([]);
    const [reportMetadata, setReportMetadata] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const printReport = () => {
        const metadata = reportMetadata;
        const data = dataList;
    
        const doc = new jsPDF('landscape', 'pt', 'a4');
        doc.setFontSize(12);
    
        // Metadata
        const title = `Report Type: ${metadata.report_type}`;
        const dateRange = `Date Range: ${metadata.start_date} - ${metadata.end_date}`;
        const generatedAt = `Generated At: ${metadata.generated_at}`;
    
        doc.text(title, 40, 30);
        doc.text(dateRange, 40, 50);
        doc.text(generatedAt, 40, 70);
    
        // Table
        const headers = [
          "Date",
          "Bill ID",
          "Customer Name",
          "Amount",
          "KPRA Tax",
          "Services",
          "Payment Method",
          "Remarks"
        ];
    
        const rows = data.map(item => [
          item.date,
          item.bill_id,
          item.customer_name,
          item.amount,
          item.kpra_tax,
          item.services,
          item.payment_method,
          item.remarks
        ]);
    
        // Fill in empty rows if less than 10 rows
        while (rows.length < 10) {
          rows.push(["", "", "", "", "", "", "", ""]);
        }
    
        doc.autoTable({
          head: [headers],
          body: rows,
          startY: 100,
          theme: 'grid',
          styles: { cellPadding: 3, fontSize: 10, valign: 'middle', halign: 'center' },
          headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
          margin: { top: 80 }
        });
    
        // Add another page if more than 10 entries
        if (data.length > 10) {
          doc.addPage();
          // Continue adding rows to the new page
          const extraRows = data.slice(10).map(item => [
            item.date,
            item.bill_id,
            item.customer_name,
            item.amount,
            item.kpra_tax,
            item.services,
            item.payment_method,
            item.remarks
          ]);
    
          while (extraRows.length < 10) {
            extraRows.push(["", "", "", "", "", "", "", ""]);
          }
    
          doc.autoTable({
            head: [headers],
            body: extraRows,
            startY: 30,
            theme: 'grid',
            styles: { cellPadding: 3, fontSize: 10, valign: 'middle', halign: 'center' },
            headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
            margin: { top: 20 }
          });
        }
    
            // Generate the file name
        const now = new Date();
        const dateStr = now.toLocaleDateString('en-GB').replace(/\//g, '');

        doc.save(`${dateStr}_${title}.pdf`);
    };


    const getdata = () => {
        axiosClient.get("reports/weekly")
            .then((response) => {
                setDataList(response.data.data);
                setReportMetadata(response.data.metadata);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDailyData = () => {
        setIsLoading(true);
        setDataList('');
        setReportMetadata('');
        axiosClient.get("reports/daily")
            .then((response) => {
                setDataList(response.data.data);
                setReportMetadata(response.data.metadata);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("Error fetching daily report:", err);
                setIsLoading(false);
            });
    };

    const getRangeData = () => {
        setIsLoading(true);
        axiosClient.get("reports/interval", {
            params: {
                start_date: startDate,
                end_date: endDate
            }
        })
        .then((response) => {
            setDataList(response.data.data);
            setReportMetadata(response.data.metadata);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log("Error fetching interval report:", err);
            setIsLoading(false);
        });
    };


    useEffect(() => {
        getdata();
    }, []);




    return (
        <div className="container">
            <main>
                <h1>Restaurant Report</h1>
                {reportMetadata.report_type && (
                    <div className="entry-four">
                        <h4 className="color-info-dark">Report Type:</h4>
                        <p>{reportMetadata.report_type}</p>
                        <h4 className="color-info-dark">Generated At:</h4>
                        <p>{reportMetadata.generated_at}</p>
                        <h4 className="color-info-dark">Start Date:</h4>
                        <p>{reportMetadata.start_date}</p>
                        <h4 className="color-info-dark">End Date:</h4>
                        <p>{reportMetadata.end_date}</p>
                    </div>
                )}
                <div className="recent-orders">
                <table>
                        <thead>
                            <tr>
                                <th>Sr.</th>
                                <th>Bill ID</th>
                                <th>Customer</th>
                                <th>Bill Date</th>
                                <th>Total Amount</th>
                                <th>KPRA Tax 5%</th>
                                <th>Services</th>
                                <th>Payment Method</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        {isLoading ? (
                            <tbody>
                                <tr>
                                    <td colSpan="9"><Loader/></td>
                                </tr>
                            </tbody>
                        ) : dataList.length > 0 ? (
                            <tbody>
                                {dataList.map((bill, index) => (
                                    <tr key={bill.bill_id}>
                                        <td className="primary">{index + 1}</td>
                                        <td className="info">{bill.bill_id}</td>
                                        <td className="info">{bill.customer_name}</td>
                                        <td className="info">{bill.date}</td>
                                        <td className="info"><b>{bill.amount}</b></td>
                                        <td className="info">{bill.kpra_tax}</td>
                                        <td className="info">{bill.services}</td>
                                        <td className="info">{bill.payment_method}</td>
                                        <td className="info">{bill.remarks}</td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="9">No records found</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </main>
            <main>
                <div className="" style={{ margin: '5.6rem 0 0 0', display: 'flex', justifyContent: 'center' }}>
                    <div className="form">
                        <h2>Advance Reports</h2>
                        <div className="entry-block">
                            <input type="text" placeholder="Select Daily or Get By Date Range" disabled/>
                            <select required onChange={handleOptionChange} value={selectedOption}>
                                <option value="">--Choose an Option--</option>
                                <option value="daily">Daily Report</option>
                                <option value="weekly">Date-Range Report</option>
                            </select>

                            {selectedOption === 'weekly' && (
                                <>
                                    <label>From (older date)</label>
                                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            
                                    <label>To (recent date)</label>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </>
                            )}
                        </div>

                        {selectedOption === 'daily' ? (
                            <button className="save" onClick={getDailyData}>Get Today's Report</button>
                        ) : (
                            <button className="save" onClick={getRangeData}>Date Range Report</button>
                        )}
                    </div>
                </div>
                <button className="save" onClick={printReport}>Print Report</button>
            </main>
        </div>
    );
};
export default DailyDinningReport