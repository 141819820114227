import React from "react";
import { useEffect, useState, useMemo } from "react";
import axiosClient from "../axios-client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {InfinitySpin} from 'react-loader-spinner';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import visioninn from "../Images/visioninn.png"

const PaymentRecord = () => {
  const [updateId, setUpdateId] = useState();
  const [sideModel, setSideModel] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [salaryModel, setSalaryModel] = useState(false);

  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [cnicNumber, setCnicNumber] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [bookingstatus, setBookingStatus] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");
  const [customerData, setCustomerData] = useState("");
  const [salaryPayable, setSalaryPayable] = useState("");
  const [salaryAttendance, setSalaryAttendance] = useState("");

  const [filterType, setFilterType] = useState(""); // Store the selected filter type
  const [startDate, setStartDate] = useState(""); // Store start date
  const [endDate, setEndDate] = useState(""); // Store end date

  const [datalist, setDatalist] = useState([]);
  const [employeeList, setEmployeesList] = useState([]);

  const clearDataValues = () => {
    // setCustomerData(['']);
    setDateOfIssue("");
    setCustomerSearch("");
  };

  const hanldeSubmit = () => {
    let errorMessage = "";

    // Validate date
    if (!customerSearch) {
      errorMessage += "Enter any thing to get customer.\n";
    }

    // Create payload object with expense data
    const payload = {
      SearchID: customerSearch,
    };

    // Send POST request to backend to add expense
    axiosClient
      .get(`employee/salary/${customerSearch}`, payload)
      .then((response) => {
        // Handle success
        toast.success("Details fetched successfully.", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });

        setCustomerData(response.data);
        // Clear form fields after successful submission
        clearDataValues();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const errors = error.response.data.errors;
          let errorMessage = "";
          for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
              errorMessage += errors[key].join("\n") + "\n";
            }
          }
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
          });
        } else {
          console.error("Error adding expense:", error);
          toast.error("Failed to add expense. Please try again later.", {
            position: "top-right",
            autoClose: 2500,
            theme: "colored",
          });
        }
      });
  };

  const updateData = (item) => {
    setSideModel(true);
    setUpdateId(item.id);
    setName(item.name);
    setFatherName(item.father_name);
    setCnicNumber(item.cnic_number);
    setContactNumber(item.contact_number);
    setPassportNumber(item.passport_number);
    setPlaceOfIssue(item.place_of_issue);
    setDateOfIssue(item.date_of_issue);
    setCompleteAddress(item.complete_address);
    setBookingStatus(item.status);
  };
  const handleUpdate = (data) => {
    setSideModel(false);

    let payload = {
      name: name,
      father_name: fatherName,
      cnic_number: cnicNumber,
      contact_number: contactNumber,
      passport_number: passportNumber,
      place_of_issue: placeOfIssue,
      date_of_issue: dateOfIssue,
      complete_address: completeAddress,
      status: bookingstatus,
    };
    axiosClient
      .put(`customer/${updateId}`, payload)
      .then((response) => {
        toast.success(`${name} Updated Sucessfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getdata();
        clearDataValues();
      })
      .catch((err) => {
        console.log(err);
        var firstErrorMessage = "";
        if (
          err.response.data.error &&
          Object.keys(err.response.data.error).length != 0
        ) {
          var errors = err.response.data.error;
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
      });
  };
  const handlePayment = (data) => {
    let payload = {
      employee_id: customerData.employee.id,
      amount: salaryPayable,
      attendance: salaryAttendance,
      effective_date: dateOfIssue,
    };
    axiosClient
      .post(`salaries`, payload)
      .then((response) => {
        toast.success(`${customerData.employee.name} Salary Paid Sucessfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getdata();
        setSalaryModel(false);
        clearDataValues();
        setCustomerData("");
      })
      .catch((err) => {
        console.log(err);
        var firstErrorMessage = "";
        if (
          err.response.data.error &&
          Object.keys(err.response.data.error).length != 0
        ) {
          var errors = err.response.data.error;
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
      });
  };
  const deleteCity = (item) => {
    Swal.fire({
      title: "please confirm?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete(`salaries/${item.id}`)
          .then((response) => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getdata();
          })
          .catch((error) => {
            toast.error("record not found", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr.",
        accessor: (row, index) => index + 1, // Calculate the index
      },
      {
        Header: "ID",
        accessor: "id", // Use the 'id' field from the response
      },
      {
        Header: "Type",
        accessor: "type", // "type" indicates whether it's "salary" or "expense"
      },
      {
        Header: "Name",
        accessor: "name", // Use the unified 'name' field from the response
      },
      {
        Header: "Title",
        accessor: "title", // Use the 'title' field from the response
      },
      {
        Header: "Amount",
        accessor: "amount", // Amount of the expense or salary
      },
      {
        Header: "Date",
        accessor: "date", // Payment or Expense date
        Cell: ({ value }) => (value ? value.substring(0, 10) : ""), // Format date
      },
      {
        Header: "Created AT",
        accessor: "created_at",
        Cell: ({ value }) => (value ? value.substring(0, 10) : ""), // Format created_at
      },
      {
        Header: "Updated AT",
        accessor: "updated_at",
        Cell: ({ value }) => (value ? value.substring(0, 10) : ""), // Format updated_at
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: datalist,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const getdata = () => {
    setIsloading(true);
    axiosClient
      .get("get-all-expenses")
      .then((response) => {
        setDatalist(response.data);
        console.log(datalist)
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PaySalary = () => {
    setSalaryModel(true);
  };

  const getexpensedata = () => {
    axiosClient
      .get("employee")
      .then((response) => {
        setEmployeesList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // all bills print
  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    setStartDate(""); // Reset dates when the filter type changes
    setEndDate("");
  };

  const getFilteredBills = () => {
    let filteredBills = datalist;

    if (!datalist || filteredBills.length === 0) {
      alert("No Paymemts found for the selected date range!");
      return [];
    }

    // Filter by today's bills
    if (filterType === "daily") {
      const today = new Date().toISOString().split("T")[0];
      filteredBills = datalist.filter((bill) => bill.date === today);
    }

    // Filter by date range
    if (filterType === "daterange" && startDate && endDate) {
      filteredBills = datalist.filter(
        (bill) => bill.date >= startDate && bill.date <= endDate
      );
    }

    return filteredBills;
  };

  // PDF generation for filtered bills
  const handlePrintFiltered = () => {
    const filteredBills = getFilteredBills();

    // Check if filtered bills list is empty
    if (filteredBills.length === 0) {
      alert("No Paymemts found for the selected date range!");
      return; // Do not proceed with PDF generation
    }

    generatePDF(filteredBills, filterType, startDate, endDate);
  };

  // PDF generation for all bills
  const handlePrintAll = () => {
    if (datalist.length === 0) {
      alert("No Paymemts available to print!");
      return;
    }
    generatePDF(datalist);
  };

  // Function to generate PDF using jsPDF
  const generatePDF = (bills, filterType, startDate = "", endDate = "") => {
    const doc = new jsPDF();

    // Hotel information and logo details
    const hotelName = "Vision Inn";
    const hotelContact = "Hotel & Restaurant";
    const hotelAddress = "Toheed abad, Nathia Gali (main Murree, Abbottabad road) KPK";
    const logoUrl = visioninn; // Replace with your hotel logo or path to the image

    // Get the current date and time in the desired format
    const currentDate = new Date();
    const formattedDate = `${String(currentDate.getDate()).padStart(
      2,
      "0"
    )}${String(currentDate.getMonth() + 1).padStart(2, "0")}${String(
      currentDate.getFullYear()
    ).slice(-2)}`; // Format: DDMMYY
    const formattedTime = currentDate.toLocaleTimeString(); // Format: HH:MM:SS AM/PM
    const printDate = currentDate.toLocaleDateString(); // Format: HH:MM:SS AM/PM

    // Load the logo image (if available)
    const imgWidth = 20; // Set width for the logo image
    const imgHeight = 20; // Set height for the logo image
    if (logoUrl) {
      doc.addImage(logoUrl, "PNG", 170, 10, imgWidth, imgHeight);
    }

    // Add hotel information on the right side
    doc.setFontSize(15);
    doc.text(hotelName, 15, 15, { align: "left" }); // Hotel name
    doc.setFontSize(10);
    doc.text(hotelContact, 15, 20, { align: "left" }); // Contact information
    doc.text(hotelAddress, 15, 25, { align: "left" }); // Address

    // Add the date and time below the hotel information
    
    doc.text(`Date: ${printDate}`, 15, 35, { align: "left" }); // Date in DDMMYY format
    doc.text(`Time: ${formattedTime}`, 15, 40, { align: "left" }); // Time

    // Add title based on the filter type
    doc.setFontSize(10);
    let filterHeading = "";

    // Determine heading based on the filterType
    switch (filterType) {
      case "daily":
        filterHeading = `Today’s Paymemts - Date: ${formattedDate}`;
        break;
      case "daterange":
        filterHeading = `Paymemts from ${startDate} to ${endDate}`;
        break;
      case "all":
      default:
        filterHeading = "All Paymemts List";
        break;
    }

    doc.text(filterHeading, 15, 45, { align: "left" }); // Address

    // Define table columns
    const columns = [
      { header: "Sr.", dataKey: "id" },
      { header: "Name", dataKey: "name" }, // Updated to reflect the unified 'name' field
      { header: "Title", dataKey: "title" }, // For titles like 'Salary Payment'
      { header: "Type", dataKey: "type" }, // Salary or Expense
      { header: "Amount", dataKey: "amount" },
      { header: "Date", dataKey: "date" }, // Updated to use 'date' field
    ];

    // Format the bills data for PDF
    const rows = bills.map((bill, index) => ({
      id: index + 1,
      name: bill.name || "N/A", // Use 'name' from response
      title: bill.title || "N/A", // Use 'title' if available
      type: bill.type || "N/A", // Use 'type' to differentiate between salary and expense
      amount: bill.amount || "N/A",
      date: bill.date || "N/A", // Updated to 'date' field
    }));


    // Use jsPDF AutoTable plugin to generate the table
    doc.autoTable({
      startY: 55, // Start the table below the header and date/time
      head: [columns.map((col) => col.header)],
      body: rows.map((row) => Object.values(row)),
    });

    // Save the PDF with a file name that includes the formatted date
    const fileName = `payments_report_${formattedDate}.pdf`;
    doc.save(fileName);
  };

  useEffect(() => {
    getdata();
    getexpensedata();
  }, []);
  if (isloading) {
    return (
      <div className="containerfull">
        <main>
          <h1>Payment Record</h1>
          <div className="recent-orders">
            <LoadingComponent />
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="containerfull">
      <main>
        <h1>Payment Record</h1>
        <div className="mt-1 table-search d-flex">
          <input
            type="text"
            placeholder="Search in table..."
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <div>
            <button
              className="py-1"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="py-1"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <span className="py-1">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <select
              className="tablePagination"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="recent-orders">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sorted-desc"
                            : "sorted-asc"
                          : ""
                      }
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tablePagination-no">
          {pageOptions.length > 1 &&
            pageOptions.map((_, index) => (
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={`tablePagination-btn ${
                  index === pageIndex ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
        </div>

        <div className="entry-four mt-2">
          <label>Print By Date: </label>
          <select
            value={filterType}
            onChange={handleFilterTypeChange}
            className="form-control mx-2"
          >
            <option value="">--Select Filter--</option>
            <option value="daily">Today’s Paymemts</option>
            <option value="daterange">Date Range</option>
            <option value="alldates">All Paymemts</option>
          </select>

          {/* Conditional Date Range Inputs */}
          {filterType === "daterange" && (
            <>
              <label>Start Date: </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="form-control mx-2"
              />
              <label>End Date: </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="form-control mx-2"
              />
            </>
          )}
        </div>

        {/* Print Buttons */}
        <div className="d-flex mt-2">
          {/* Conditionally Render Print Filtered Button */}
          {filterType && (
            <button className="save" onClick={handlePrintFiltered}>
              Print Filtered List
            </button>
          )}
        </div>
      </main>
    </div>
  );
};

export default PaymentRecord