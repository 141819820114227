import React from "react";
import { useEffect, useState,useMemo } from "react";
import axiosClient from "../axios-client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {toast} from "react-toastify"
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";
import jsPDF from "jspdf";
import "jspdf-autotable";
import visioninn from "../Images/visioninn.png"

const NewInventory = () => {
  const [plot_Type, setPlot_Type] = useState();
  const [updateId, setUpdateId] = useState();
  const [totalPlot, setTotalPlot] = useState('');
  const [description, setDescription] = useState('');
  const [inventoryHead, setInventoryHead] = useState("");
  const [sideModel, setSideModel] =useState(false)
  const [isloading, setIsloading] = useState(false)
  const [roomNumber, setRoomNumber] = useState();
  const [block, setBlock] = useState();
  const [floor, setFloor] = useState();
  const [type, setType] = useState();
  const [product_name, setProduct_name] = useState();
  const [quantity, setQuantity] = useState();
  const [head, setHead] = useState([]);

  const [updatedProduct_name, setUpdatedProduct_name] = useState();
  const [updatedQuantity, setUpdatedQuantity] = useState();
  const [updatedPrice, setUpdatedPrice] = useState();


  const [datalist, setDatalist] = useState([]);



  const hanldeSubmit = () => {

    let errorMessage = '';

    // Check each required field
    if (!roomNumber) {
      errorMessage += "item label is required.\n";
    }
  
    if (!block) {
      errorMessage += "Quantity is required.\n";
    }
  
    if (!floor) {
      errorMessage += "Price is required.\n";
    }
  
    // If any required field is missing, show error message
    if (errorMessage) {
      toast.error(errorMessage.trim(), { // trim() removes trailing whitespace
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
      return;
    }
   
    let payload = {
        product_name: roomNumber,
        quantity: block,
        price: floor,
        inventory_head: inventoryHead
      
    };
    axiosClient.post("inventory", payload)
      .then((response) => {
        toast.success( `${roomNumber} Added Sucessfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        // Clear fields after successful submission
        setRoomNumber(''); // Clear roomNumber state
        setBlock(''); // Clear block state
        setFloor(''); // Clear floor state
        setType(''); // Clear type state
        getdata();
      })
      .catch((err) => {
        console.log(err)
        var firstErrorMessage = '';
        if (err.response.data.error && Object.keys(err.response.data.error).length != 0) {
          var errors = err.response.data.error
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
      });
  };

  const updateCity =(item)=>{
    setSideModel(true)
    setUpdateId(item.id)
    setUpdatedProduct_name(item.product_name);
    setUpdatedQuantity(item.quantity);
    setUpdatedPrice(item.price);
  }
  const handleUpdate =(data)=>{
    setSideModel(false)

    let payload = {
        product_name: updatedProduct_name,
        quantity: updatedQuantity,
        price: updatedPrice,
      };
      axiosClient.put(`inventory/${updateId}`, payload)
        .then((response) => {
          toast.success( `${product_name} Updated Sucessfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
          getdata();
        })
        .catch((err) => {
          console.log(err)
          var firstErrorMessage = '';
          if (err.response.data.error && Object.keys(err.response.data.error).length != 0) {
            var errors = err.response.data.error
            const errorMessages = Object.values(errors); // Get an array of all error messages
            firstErrorMessage = errorMessages.shift(); // Get the first error message
            toast.error(firstErrorMessage[0], {
              position: "top-right",
              autoClose: 1500,
              theme: "colored",
            });
          } else {
            // console.log('firstErrorMessage',firstErrorMessage[0])
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 1500,
              theme: "colored",
            });
          }
        });
    
  }

const deleteCity = (item) => {
  Swal.fire({
  title: "please confirm?",
  text: "You won't be able to revert this!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!",
  }).then((result) => {
      if (result.isConfirmed) {
          axiosClient.delete(`inventory/${item.id}`)
          .then((response) => {
              toast.success(response.data.message, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
              });
              getdata();
          })
      .catch((error) => {
          toast.error("record not found", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
  });
};
  })
}
const columns = useMemo(
() => [
  {
    Header: "Sr.",
    accessor: (row, index) => index + 1, // Calculate the index
  },
  {
    Header: "Item",
    accessor: "product_name",
  },
  {
    Header: "Head",
    accessor: "inventory_head",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },{
    Header:"Price",
    accessor:"price"

  },
  {
    Header: "Created AT",
    accessor: "created_at",
    Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
  },
  {
    Header: "Updated AT",
    accessor: "updated_at",
    Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
  },
  {
    Header: "Action",
    Cell: ({ row }) => (
      <div className="flex">
        <a
          className="primary"
          onClick={(e) => {
            e.preventDefault();
            updateCity(row.original);
          }}
        >
          Edit
        </a>
        <a
          className="warning"
          onClick={(e) => {
            e.preventDefault();
            deleteCity(row.original);
          }}
        >
          Delete
        </a>
      </div>
    ),
  },
],
[]
);

const {
getTableProps,
getTableBodyProps,
headerGroups,
prepareRow,
page,
canPreviousPage,
canNextPage,
pageOptions,
gotoPage,
nextPage,
previousPage,
setPageSize,
state: { pageIndex, pageSize, globalFilter },
setGlobalFilter,
} = useTable(
{
  columns,
  data: datalist,
  initialState: { pageIndex: 0, pageSize: 10 },
},
useGlobalFilter,
useSortBy,
usePagination
);
  //receive data
  const getdata = () => {
    setIsloading(true)
    axiosClient
      .get("inventory")
      .then((response) => {
        setDatalist(response.data);
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHeadData = () => {

    // Fetch positions
    axiosClient.get('inventoryhead')
    .then(response => {
        setHead(response.data);
        console.log("response data",response.data)
    })
    .catch(error => {
        toast.error('Error fetching Data. Please try again later.');
    });
    
  }
  const hanldeEdit = (data) => {
    alert(data);
  };

  const PrintPDF = () => {
    alert("new list pdf")
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.setFontSize(12);

    // Metadata for the report
    const hotel = "Visioninn Hotel and Resturant";
    const title = "Inventory Report";
    const generatedAt = `Generated At: ${new Date().toLocaleDateString()}`;

    // Adding metadata to the PDF
    doc.text(hotel, 40, 30);
    doc.text(title, 40, 50);
    doc.text(generatedAt, 40, 70);

    // Adding the image to the right side of the metadata
    const imgWidth = 50;
    const imgHeight = 50;
    const xOffset = 650;
    const yOffset = 30;

    doc.addImage(visioninn, 'PNG', xOffset, yOffset, imgWidth, imgHeight);

    // Inventory Data Table headers
    const inventoryHeaders = [
        "ID", 
        "Product Name",
        "Inventory Head", 
        "Quantity", 
        "Price"
    ];

    // Mapping dataList (inventory data) to rows
    const inventoryRows = datalist.map(item => [
        item.id,
        item.product_name,
        item.inventory_head,
        item.quantity,
        item.price
    ]);

    // If there are fewer than 10 rows, add empty rows to fill space
    while (inventoryRows.length < 10) {
        inventoryRows.push(["", "", "", "", ""]);
    }

    // Creating the Inventory table in the PDF
    doc.autoTable({
        head: [inventoryHeaders],
        body: inventoryRows,
        startY: 120, // Adjust for space after the image
        theme: 'grid',
        styles: { cellPadding: 3, fontSize: 10, valign: 'middle', halign: 'center' },
        headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
        margin: { top: 80 }
    });

    // Save the PDF file
    doc.save(`InventoryReport_${new Date().toLocaleDateString('en-GB').replace(/\//g, '')}.pdf`);
  };

  useEffect(() => {
    getdata();
    getHeadData();
  }, []);

  if (isloading) {
    return <div className="container"><main><h1>Inventory List</h1><div className="recent-orders"><LoadingComponent /></div></main></div>;
  }

  return (
    <div className="container">
      <main>
        <h1>Inventory List</h1>
        <div className="mt-1 table-search d-flex">
          <input
            type="text"
            placeholder="Search in table..."
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <div>
            <button
              className="py-1"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="py-1"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <span className="py-1">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <select
              className="tablePagination"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="recent-orders">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps()
                      )}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sorted-desc"
                            : "sorted-asc"
                          : ""
                      }
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tablePagination-no">
          {pageOptions.length > 1 &&
            pageOptions.map((_, index) => (
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={`tablePagination-btn ${
                  index === pageIndex ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
        </div>
        <button className="save" onClick={PrintPDF}>Print PDF</button>
      </main>
      <main>
        <div className="" style={{margin:'5.6rem 0 0 0', display:'flex', justifyContent:'center' }}>
            <div className="form">
                <h2>Add New Item</h2>
                <div className="entry-block">
                    <label>Item</label>
                    <input type="text" value={roomNumber} onChange={(e) => setRoomNumber(e.target.value)} required />

                    <label>Quantity</label>
                    <input type="text" value={block} onChange={(e) => setBlock(e.target.value)} required />

                    <label>Price</label>
                    <input type="text" value={floor} onChange={(e) => setFloor(e.target.value)} required />
                    
                    <label>Inventory Head</label>
                    <select name="inventoryHead" onChange={(e)=>setInventoryHead(e.target.value)}>
                        <option>Please Select Head</option>
                      {head.map((item, index) => (
                        <option key={index} value={item.title}>{index+1}, {item.title}</option>
                      ))}
                    </select>


                </div>
                <button className="save" onClick={hanldeSubmit}>Add Item</button>
            </div>
        </div>
      </main>
      {sideModel &&

        <div className={`model-side ${sideModel ? "model-side-show" : ""}`}>
        <div className="model-side-closebtn" onClick={() => setSideModel(false)}>
        <span className="material-symbols-outlined rotate-icon">close</span>
        </div>
        <div className="form">
          <h2>Update Item</h2>
          <div className="entry-block">
              <label>Item</label>
              <input type="text" value={updatedProduct_name} onChange={(e) => setUpdatedProduct_name(e.target.value)} required />

              <label>Quantity</label>
              <input type="text" value={updatedQuantity} onChange={(e) => setUpdatedQuantity(e.target.value)} required />

              <label>Price</label>
              <input type="text" value={updatedPrice} onChange={(e) => setUpdatedPrice(e.target.value)} required />

          </div>
        <button className="save" onClick={handleUpdate}>Update Room</button>
        </div>
        </div>
      }
    </div>
  );
};

export default NewInventory
