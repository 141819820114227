import React from "react";
import { useEffect, useState, useMemo } from "react";
import axiosClient from "../axios-client";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify"
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";

const WeeklyMenu = () => {
    const [updateId, setUpdateId] = useState();
    const [sideModel, setSideModel] = useState(false)
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [expenseHeadId, setExpenseHeadId] = useState('');

    const [selectedUpdatedDay, setSelectedUpdatedDay] = useState();
    const [selectedUpdatedTime, setSelectedUpdatedTime] = useState();
    const [selectedUpdatedMenuItem, setSelectedUpdatedMenuItem] = useState();

    const [quantity, setQuantity] = useState();
    const [price, setPrice] = useState();
    const [uom, setUOM] = useState();
    const [name, setName] = useState();
    const [datalist, setDatalist] = useState([]);
    const [headlist, setHeadlist] = useState([]);

    const [selectedDay, setSelectedDay] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [menuItems, setMenuItems] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [isloading, setIsloading] = useState(false);


    const handleDayChange = (e) => {
        setSelectedDay(e.target.value);
    };

    const handleTimeChange = (e) => {
        setSelectedTime(e.target.value);
    };

    const handleMenuItemChange = (e) => {
        setSelectedMenuItem(e.target.value);
    };

    const handleSubmit = () => {
        let errorMessage = '';
    
        // Validate date
        if (!selectedDay) {
            errorMessage += "Date is required.\n";
        }
    
        // Validate title
        if (!selectedTime) {
            errorMessage += "Menu Time required.\n";
        }

        if (!selectedMenuItem) {
            errorMessage += "Menu Item required.\n";
        }

    
        // If any validation fails, show error message and return
        if (errorMessage) {
            toast.error(errorMessage.trim(), {
                position: "top-right",
                autoClose: 2500,
                theme: "colored",
            });
            return;
        }
    
        // Create payload object with expense data
        const payload = {
            id: name,
            day: selectedDay,
            time: selectedTime,
            menu_item_id: selectedMenuItem,
        };
    
        // Send POST request to backend to add expense
        axiosClient.post("weekly-menus", payload)
            .then((response) => {
                // Handle success
                toast.success(`MenuItem added successfully.`, {
                    position: "top-right",
                    autoClose: 2000,
                    theme: "colored",
                });
    
                // Clear form fields after successful submission
                getdata();
                setName('');
                setAmount('');
                setDescription('');
                setQuantity('');
                setUOM('');

            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    let errorMessage = '';
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            errorMessage += errors[key].join('\n') + '\n';
                        }
                    }
                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "colored",
                    });
                } else {
                    console.error("Error adding expense:", error);
                    toast.error("Failed to add expense. Please try again later.", {
                        position: "top-right",
                        autoClose: 2500,
                        theme: "colored",
                    });
                }
            });
    };
    
    const updateCity = (item) => {
        setSideModel(true)
        setUpdateId(item.id);
        setName(item.name);
        setDescription(item.description);
        setAmount(item.price);
        setUOM(item.unit_of_measurement)
        setQuantity(item.quantity);
    }
    const handleUpdate = (data) => {
        setSideModel(false)

        const payload = {
            id: selectedUpdatedMenuItem,
            day: selectedUpdatedDay,
            time: selectedUpdatedTime,
            menu_item_id: selectedUpdatedMenuItem,
        };
        
        axiosClient.put(`weekly-menus/${selectedUpdatedMenuItem}`, payload)
            .then((response) => {
                toast.success(`Menu Item Updated Sucessfully`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                getdata();
                setName('');
                setAmount('');
                setDescription('');
                setQuantity('');
                setUOM('');
            })
            .catch((err) => {
                console.log(err)
                var firstErrorMessage = '';
                if (err.response.data.error && Object.keys(err.response.data.error).length != 0) {
                    var errors = err.response.data.error
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "top-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            });

    }
    const deleteItem = (item) => {
        Swal.fire({
            title: "please confirm?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`weekly-menu/${item.id}`)
                    .then((response) => {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        getdata();
                    })
                    .catch((error) => {
                        toast.error("record not found", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    });
            };
        })
    }


    const columns = useMemo(
        () => [
          {
            Header: "Sr.",
            accessor: (row, index) => index + 1, // Calculate the index
          },
          {
            Header: "Day",
            accessor: "day",
          },
          {
            Header: "Time",
            accessor: "time",
          },
          {
            Header: "Item",
            accessor: "menu_item.name",
          },
          {
            Header: "Description",
            accessor: "menu_item.description",
          },
          {
            Header: "Created AT",
            accessor: "created_at",
            Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
          },
          {
            Header: "Updated AT",
            accessor: "updated_at",
            Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
          },
          {
            Header: "Action",
            Cell: ({ row }) => (
              <div className="flex">
                <a
                  className="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    updateCity(row.original);
                  }}
                >
                  Edit
                </a>
                <a
                  className="warning"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteItem(row.original);
                  }}
                >
                  Delete
                </a>
              </div>
            ),
          },
        ],
        []
      );
  
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
      } = useTable(
        {
          columns,
          data: datalist,
          initialState: { pageIndex: 0, pageSize: 10 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
      );

    const getdata = () => {
        setIsloading(true);
        axiosClient.get("weekly-menus")
            .then((response) => {
                setDatalist(response.data);
                setIsloading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getexpensedata = () => {
        axiosClient.get("menuitems")
            .then((response) => {
                setMenuItems(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getdata();
        getexpensedata();
    }, []);


    if (isloading) {
        return <div className="container"><main><h1>Weekly Menu</h1><div className="recent-orders"><LoadingComponent /></div></main></div>;
      }
    
      return (
        <div className="container">
            <main>
                <h1>Weekly Menu</h1>
                <div className="mt-1 table-search d-flex">
                <input
                    type="text"
                    placeholder="Search in table..."
                    value={globalFilter || ""}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                />
                <div>
                    <button
                    className="py-1"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    >
                    Previous
                    </button>
                    <button
                    className="py-1"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    >
                    Next
                    </button>
                    <span className="py-1">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                    </span>
                    <select
                    className="tablePagination"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                    {[10, 20, 50, 100].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                        </option>
                    ))}
                    </select>
                </div>
                </div>
                <div className="recent-orders">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                            {...column.getHeaderProps(
                                column.getSortByToggleProps()
                            )}
                            className={
                                column.isSorted
                                ? column.isSortedDesc
                                    ? "sorted-desc"
                                    : "sorted-asc"
                                : ""
                            }
                            >
                            {column.render("Header")}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                : ""}
                            </span>
                            </th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                            </td>
                            ))}
                        </tr>
                        );
                    })}
                    </tbody>
                </table>
                </div>
                <div className="tablePagination-no">
                {pageOptions.length > 1 &&
                    pageOptions.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => gotoPage(index)}
                        className={`tablePagination-btn ${
                        index === pageIndex ? "active" : ""
                        }`}
                    >
                        {index + 1}
                    </button>
                    ))}
                </div>
            </main>
            <main>
                <div className="" style={{ margin: '5.6rem 0 0 0', display: 'flex', justifyContent: 'center' }}>
                    <div className="form">
                        <h2>Add New Item</h2>
                        <div className="entry-one">
                            <label>GUIDE</label>
                            <input placeholder="'Monday', 'Breakfast', 'DISH'" disabled></input>

                            <label>Day</label>
                            <select value={selectedDay} onChange={handleDayChange}>
                                <option value="">Select Day</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                            </select>

                            <label>Time</label>
                            <select value={selectedTime} onChange={handleTimeChange}>
                                <option value="">Select Time</option>
                                <option value="Breakfast">Breakfast</option>
                                <option value="Lunch">Lunch</option>
                                <option value="Dinner">Dinner</option>
                            </select>

                            <label>Food Item</label>
                            <select value={selectedMenuItem} onChange={handleMenuItemChange}>
                                <option value="">Select Food Item</option>
                                {menuItems.map(item => (
                                    <option key={item.id} value={item.id}>{item.id} {item.name}</option>
                                ))}
                            </select>
                        </div>
                        <button className="save" onClick={handleSubmit}>Add Item</button>
                    </div>
                </div>
            </main>
            {sideModel &&

                <div className={`model-side ${sideModel ? "model-side-show" : ""}`}>
                    <div className="model-side-closebtn" onClick={() => setSideModel(false)}>
                        <span className="material-symbols-outlined rotate-icon">close</span>
                    </div>
                    <div className="form">
                        <h2>Update Item</h2>
                        <div className="entry-one">
                            <label>GUIDE</label>
                            <input placeholder="'Monday', 'Breakfast', 'DISH'" disabled></input>

                            <label>Day</label>
                            <select value={selectedUpdatedDay} onChange={handleDayChange}>
                                <option value="">Select Day</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                            </select>

                            <label>Time</label>
                            <select value={selectedUpdatedTime} onChange={handleTimeChange}>
                                <option value="">Select Time</option>
                                <option value="Breakfast">Breakfast</option>
                                <option value="Lunch">Lunch</option>
                                <option value="Dinner">Dinner</option>
                            </select>

                            <label>Food Item</label>
                            <select value={selectedUpdatedMenuItem} onChange={handleMenuItemChange}>
                                <option value="">Select Food Item</option>
                                {menuItems.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <button className="save" onClick={handleUpdate}>Update</button>
                    </div>
                </div>
            }
        </div>
    );
};

export default WeeklyMenu