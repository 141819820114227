import React from "react";
import { useEffect, useState,useMemo } from "react";
import axiosClient from "../axios-client";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import LoadingComponent from "../Components/LoadingComponent";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import visioninn from "../Images/visioninn.png"



const Expense = () => {
  const [updateId, setUpdateId] = useState();
  const [sideModel, setSideModel] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [expenseHeadId, setExpenseHeadId] = useState("");

  const [roomNumber, setRoomNumber] = useState();
  const [block, setBlock] = useState();
  const [floor, setFloor] = useState();
  const [type, setType] = useState();
  const [product_name, setProduct_name] = useState();
  const [quantity, setQuantity] = useState();
  const [price, setPrice] = useState();
  const [datalist, setDatalist] = useState([]);
  const [headlist, setHeadlist] = useState([]);
  const [updatedDate, setUpdatedDate] = useState([]);
  const [updatedTitle, setUpdatedTitle] = useState([]);
  const [updatedDescription, setUpdateDescription] = useState([]);
  const [updatedAmount, setUpdatedAmount] = useState([]);
  const [updatedExpenseHeadId, setUpdatedExpenseHeadId] = useState([]);

  const [selectedHead, setSelectedHead] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterType, setFilterType] = useState("");

  const hanldeSubmit = () => {
    let errorMessage = "";

    // Validate date
    if (!date) {
      errorMessage += "Date is required.\n";
    }

    // Validate title
    if (!title) {
      errorMessage += "Title is required.\n";
    }

    // Validate amount
    if (!amount || isNaN(amount) || amount <= 0) {
      errorMessage += "Valid amount is required.\n";
    }

    // Validate expense head
    if (!expenseHeadId) {
      errorMessage += "Expense Head is required.\n";
    }

    // If any validation fails, show error message and return
    if (errorMessage) {
      toast.error(errorMessage.trim(), {
        position: "top-right",
        autoClose: 2500,
        theme: "colored",
      });
      return;
    }

    // Create payload object with expense data
    const payload = {
      date: date,
      title: title,
      description: description,
      amount: amount,
      expense_head_id: expenseHeadId,
    };

    // Send POST request to backend to add expense
    axiosClient
      .post("expenses", payload)
      .then((response) => {
        // Handle success
        toast.success("Expense added successfully.", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });

        // Clear form fields after successful submission
        getdata();
        setDate("");
        setTitle("");
        setDescription("");
        setAmount("");
        setExpenseHeadId("");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          const errors = error.response.data.errors;
          let errorMessage = "";
          for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
              errorMessage += errors[key].join("\n") + "\n";
            }
          }
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            theme: "colored",
          });
        } else {
          console.error("Error adding expense:", error);
          toast.error("Failed to add expense. Please try again later.", {
            position: "top-right",
            autoClose: 2500,
            theme: "colored",
          });
        }
      });
  };

  const updateCity = (item) => {
    setSideModel(true);
    setUpdateId(item.id);
    setUpdatedTitle(item.title);
    setUpdateDescription(item.description);
    setUpdatedAmount(item.amount);
    setUpdatedDate(item.date);
  };
  const handleUpdate = (data) => {
    setSideModel(false);

    let payload = {
      date: updatedDate,
      title: updatedTitle,
      description: updatedDescription,
      amount: updatedAmount,
      expense_head_id: updatedExpenseHeadId,
    };
    axiosClient
      .put(`expenses/${updateId}`, payload)
      .then((response) => {
        toast.success(`${updatedTitle} Updated Sucessfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getdata();
        setProduct_name("");
        setQuantity("");
      })
      .catch((err) => {
        console.log(err);
        var firstErrorMessage = "";
        if (
          err.response.data.error &&
          Object.keys(err.response.data.error).length != 0
        ) {
          var errors = err.response.data.error;
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
      });
  };
  const deleteCity = (item) => {
    Swal.fire({
      title: "please confirm?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosClient
          .delete(`expenses/${item.id}`)
          .then((response) => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getdata();
          })
          .catch((error) => {
            toast.error("record not found", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: "Sr.",
        accessor: (row, index) => index + 1, // Calculate the index
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Head",
        accessor: "head_name",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Created AT",
        accessor: "created_at",
        Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
      },
      {
        Header: "Updated AT",
        accessor: "updated_at",
        Cell: ({ value }) => (value ? value.substring(0, 10) : ""),
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <div className="flex">
            <a
              className="primary"
              onClick={(e) => {
                e.preventDefault();
                updateCity(row.original);
              }}
            >
              Edit
            </a>
            <a
              className="warning"
              onClick={(e) => {
                e.preventDefault();
                deleteCity(row.original);
              }}
            >
              Delete
            </a>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: datalist,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const getdata = () => {
    setIsloading(true);
    axiosClient
      .get("expenses")
      .then((response) => {
        setDatalist(response.data);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getexpensedata = () => {
    axiosClient
      .get("expensehead")
      .then((response) => {
        setHeadlist(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getdata();
    getexpensedata();
  }, []);

  const filterData = () => {
      const today = new Date();
      const formattedToday = today.toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1); // Next day's date
      const formattedNextDay = nextDay.toISOString().split('T')[0];

      // Debug output for dates
      console.log('Today:', formattedToday);
      console.log('Next Day:', formattedNextDay);

      // Apply filter based on filterType (daily or daterange)
      return datalist.filter(item => {
          const itemDate = item.date; // item.date from API is already in "YYYY-MM-DD" format

          // Daily expense filtering: filter items where date is today
          const isDailyExpense = filterType === "daily"
              ? itemDate >= formattedToday && itemDate < formattedNextDay
              : false;

          // Date range filtering: filter items between startDate and endDate
          const isWithinDateRange = filterType === "daterange"
              ? new Date(itemDate) >= new Date(startDate) && new Date(itemDate) <= new Date(endDate)
              : false;

          // Debug output for filtering logic
          console.log(`Filtering item with date: ${itemDate}`);
          console.log(`isDailyExpense: ${isDailyExpense}, isWithinDateRange: ${isWithinDateRange}`);

          // Head filtering: filter items by selected head, if any
          const isMatchingHead = selectedHead ? item.head_name === selectedHead : true;

          // Final filter condition
          return (isDailyExpense || isWithinDateRange) && isMatchingHead;
      });
  };

  // Handle filter type change
  const handleFilterTypeChange = (e) => {
      const value = e.target.value;
      setFilterType(value);

      if (value === "daily") {
          const today = new Date().toISOString().split('T')[0]; // Today's date
          const tomorrow = new Date();
          tomorrow.setDate(new Date().getDate() + 1); // Tomorrow's date
          setStartDate(today);
          setEndDate(tomorrow.toISOString().split('T')[0]);
      }
  };


  // PDF Generation Function
  const generatePDF = (filteredData, reportTitle) => {
    const doc = new jsPDF("landscape", "pt", "a4");
    doc.setFontSize(12);
    doc.text(reportTitle, 40, 30);

    const headers = ["ID", "Date", "Title", "Amount", "Head Name"];
    const rows = filteredData.map((item) => [
      item.id,
      item.date,
      item.title,
      item.amount,
      item.head_name,
    ]);

    doc.autoTable({
      head: [headers],
      body: rows,
      startY: 60,
    });

    doc.save(`${reportTitle}.pdf`);
  };

  // Handling print of the filtered list
  const handlePrintFiltered = () => {
    const filteredData = filterData();
    generatePDF(filteredData, "Filtered List");
  };

  // Handling print of all data
  const handlePrintAll = () => {
    generatePDF(datalist, "All List");
  };

  if (isloading) {
    return (
      <div className="container">
        <main>
          <h1>Expense List</h1>
          <div className="recent-orders">
            <LoadingComponent />
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="container">
      <main>
        <h1>Expense List</h1>
        <div className="mt-1 table-search d-flex">
          <input
            type="text"
            placeholder="Search in table..."
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <div>
            <button
              className="py-1"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="py-1"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <span className="py-1">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <select
              className="tablePagination"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="recent-orders">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        column.isSorted
                          ? column.isSortedDesc
                            ? "sorted-desc"
                            : "sorted-asc"
                          : ""
                      }
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="tablePagination-no">
          {pageOptions.length > 1 &&
            pageOptions.map((_, index) => (
              <button
                key={index}
                onClick={() => gotoPage(index)}
                className={`tablePagination-btn ${
                  index === pageIndex ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
        </div>

        <div className="entry-four mt-2">
            {/* Head Dropdown */}
            <label>Print By Head: </label>
            <select
                value={selectedHead}
                onChange={(e) => setSelectedHead(e.target.value)}
                className="form-control mx-2"
            >
                <option value="">--Select Head--</option>
                {headlist.map((head, index) => (
                    <option key={index} value={head.name}>{head.name}</option>
                ))}
            </select>

            {/* Date Filter Dropdown */}
            <label>Print By Date: </label>
            <select 
                value={filterType} 
                onChange={handleFilterTypeChange}  // Updated to use the new handler
                className="form-control mx-2"
            >
                <option value="">--Select Filter--</option>
                <option value="daily">Daily Expense</option>
                <option value="daterange">Date Range</option>
            </select>


            {/* Conditional Date Range Inputs */}
            {filterType === "daterange" && (
                <>
                    <label>Start Date: </label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control mx-2"
                    />
                    <label>End Date: </label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control mx-2"
                    />
                </>
            )}
        </div>

        <div className="d-flex mt-2">
            {/* Conditionally Render Buttons */}
            {(selectedHead || filterType) && (
                <button className="save" onClick={handlePrintFiltered}>
                    Print Filtered List
                </button>
            )}
            <button className="save" onClick={handlePrintAll}>Print All List</button>
        </div>
        
      </main>
      <main>
        <div
          className=""
          style={{
            margin: "5.6rem 0 0 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="form">
            <h2>Add Expense</h2>
            <div className="entry-block">
              <label>Date</label>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />

              <label>Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />

              <label>Description</label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              <label>Amount</label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />

              <label>Expense Head</label>
              <select
                value={expenseHeadId}
                onChange={(e) => setExpenseHeadId(e.target.value)}
                required
              >
                <option value="">--Choose an Option--</option>
                {headlist.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <button className="save" onClick={hanldeSubmit}>
              Add Expense
            </button>
          </div>
        </div>
      </main>
      {sideModel && (
        <div className={`model-side ${sideModel ? "model-side-show" : ""}`}>
          <div
            className="model-side-closebtn"
            onClick={() => setSideModel(false)}
          >
            <span className="material-symbols-outlined rotate-icon">close</span>
          </div>
          <div className="form">
            <h2>Update Expense</h2>
            <div className="entry-block">
              <label>Date</label>
              <input
                type="date"
                value={updatedDate}
                onChange={(e) => setUpdatedDate(e.target.value)}
                required
              />

              <label>Title</label>
              <input
                type="text"
                value={updatedTitle}
                onChange={(e) => setUpdatedTitle(e.target.value)}
                required
              />

              <label>Description</label>
              <textarea
                type="text"
                value={updatedDescription}
                onChange={(e) => setUpdateDescription(e.target.value)}
              />

              <label>Amount</label>
              <input
                type="number"
                value={updatedAmount}
                onChange={(e) => setUpdatedAmount(e.target.value)}
                required
              />

              <label>Expense Head</label>
              <select
                value={updatedExpenseHeadId}
                onChange={(e) => setUpdatedExpenseHeadId(e.target.value)}
                required
              >
                <option value="">--Choose an Option--</option>
                {headlist.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <button className="save" onClick={handleUpdate}>
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Expense